@import 'nib'
@import 'utils.styl'

[data-page='notices']
	.row
		&.grid
			position relative
			
	.opacity
		position absolute
		right 0
		left 0
		bottom 0
		width 100%
		height 50px
		z-index 10
		opacity .7
		background #fff
		
	.button-block-notices
		position absolute
		right 0
		left 0
		bottom 0
		margin auto
		z-index 20
		width 300px