@import 'nib'
@import 'utils.styl'

[data-page='indicate-patient']
[data-page='indicate-professional']
	padding 0
		
	.block
		background-color $color-white
		padding 22px 30px 0
		border-radius 5px
		border-style solid 
		border-width 1px
		border-bottom-width 5px
		border-color $color-blue--light
		
		label
			margin-bottom 15px
			
		input
			margin-bottom 25px

		.form-button
			padding 15px 0
			margin-top 13px
				
		.text
			color $color-gray
			font-size 16px
			margin 18px 0
			
	section
		width 100%
		display table
		min-height 465px
		background url( '../img/indicate-patient/bg-indicate-patient.jpg' )
		background-size cover
		padding 48px 0 68px
		
	.col-img
		min-height 423px
		
		img 
			position absolute
			top 0
			right 0
			left 0
			bottom 0
			margin auto
[data-page='indicate-professional']
	select
		font-style italic
	section
		width 100%
		display table
		min-height 465px
		background url( '../img/login-profissional/bg-professional-indicate.png' )
		background-size cover
		padding 48px 0 68px	



