@import 'nib'
@import 'utils.styl'

.attendance
	.label-begin-period
		display block
		
	.label-end-period
		margin-left 16px
		margin-right 19px
		
		+breakpoint( tablet )
			margin 0
		
	.label-status
		display block
		
	.button-status
		display inline-block
		min-width 132px
		border 2px solid
		text-transform uppercase
		border-radius 30px
		padding 7px 12px 
		font-weight bold
		text-align center
		font-family $font-family-titles
		font-size 16px
		margin 12px 5px 44px 0
		cursor pointer
		
		@media only screen and ( max-width 595px )
			margin-top 0
			margin-bottom 10px
			min-width 45%
			
			&:last-child
				margin-bottom 30px
			
			&:nth-child( odd )
				float left
				
			&:nth-child( even )
				float left
		
		span 
			font-weight 100
			display inline-block
			
		&.-all
			color #818282
			background transparent
			border-color #818282
			
			&.active
				color #fff
				background #818282
				border-color #818282
				
		&.-scheduled
			color #fbb731
			background transparent
			border-color #fbb731
			
			&.active
				color #fff
				background #fbb731
				border-color #fbb731
					
		&.-completed
			color #a2ba54
			background transparent
			border-color #a2ba54
			
			&.active
				color #fff
				background #a2ba54
				border-color #a2ba54
						
		&.-andamento
			color #54baa8
			background transparent
			border-color #54baa8

			&.active
				color #fff
				background #54baa8
				border-color #54baa8

		&.-canceled
			color #f14c00
			background transparent
			border-color #f14c00
						
			&.active
				color #fff
				background #f14c00
				border-color #f14c00
			
		&.-paid 
			color #a2ba54
			background transparent
			border-color #a2ba54
									
			&.active
				color #fff
				background #a2ba54
				border-color #a2ba54
				
		&.-unsucessful
			color #f14c00
			background transparent
			border-color #f14c00
												
			&.active
				color #fff
				background #f14c00
				border-color #f14c00
						
		&.-protested
			color #000
			background transparent
			border-color #000
															
			&.active
				color #fff
				background #000
				border-color #000
		&.-waiting
			color #8B4513
			background transparent
			border-color #8B4513
															
			&.active
				color #fff
				background #8B4513
				border-color #8B4513			
	.icon-calendar
		display inline-block
		vertical-align middle
		margin-left 8px
		
		+breakpoint( tablet )
			display none
		
	.input-date
		width 134px
		display inline-block
		
		+breakpoint( tablet )
			width 100%
		
	.form-button
		&.-filter
			width 165px
			float right !important
			
			@media only screen and ( max-width 490px )
				float none !important
				width 100%
			
	.reveal-modal
		width 72%
		margin-left -36%
		
		+breakpoint( mobile )
			width 90%
			margin-left -45%
			
		
		&.-cancel
			width 430px
			margin-left -215px
			
			.-transparent
				width 235px
				float left
				
			.-blue
				width 126px
				float right
				
			p 
				text-align center
				margin-bottom 25px
				
			.revel-modal-body
				clearfix()
				
			
	.table-details
		width 100%
		margin-top 30px
		
		td 
			color $color-gray
			width 33%
			padding-bottom 25px
			
			+breakpoint( mobile )
				display block 
				width 100%
			
	.text-details
		font-size 14px
		margin 30px 0 50px

	.financa
		font-family $font-family-base
		font-size 16px
		color $color-gray
		margin 15px 0 15px
		line-height 20px

	.content-details
		padding 0 50px
		
		+breakpoint( mobile )
			padding 0

	.col-table-result
		min-width 970px !important
		
		+breakpoint( tablet )
			overflow-x scroll
			
	.carousel-rows
		&.slick-dotted.slick-slider
			margin 30px 0 40px
		
		.slick-dots 
			li
				width 10px 
				height 10px 
				
				button
					background #4dbeed
					border #4dbeed
					width 100%
					height 100%
					
					&::before
						background #4dbeed
						border #4dbeed
						color transparent
						width 12px
						height 12px
						
				&.slick-active 
					button
						background transparent
						
						&::before
							background transparent
							border #4dbeed							
	
	.table-result 
		+breakpoint( tablet )
			text-align center
			
		.link-style-2
			+breakpoint( tablet )
				font-size 16px
.btn-back-view
	margin-top 30px
	min-width 45%
	border 1px solid #03a7e0
	border-radius 30px
	padding 5px 20px
	color #03a7e0 !important
	font-size 16px
	text-decoration none
	font-weight 700
	
	&:active
	&:visited
	&:hover
		color #03a7e0 !important
