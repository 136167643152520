@import 'nib'
@import '../../assets/styl/utils.styl'

header
	
	.row
		> div
			clearfix()
			
	.status 
		display none
		
	nav
		border-top solid 3px #F6F6F6

	.container
		padding 0
		
		+breakpoint( tablet )
			padding 0 15px

	.logo
		float left
	.btn-login-mobile
		margin-top 20px
		width 150px
		
	.nav-list
		a
			&:hover,
			&:focus
				color #266988

				&::after
					opacity 1

	.user-list
		float right
		list-style none
		margin 22px 0
		padding 0
		
		.img-mask
			width 33px
			height 33px
			margin 0 

		li
			float left
			margin-right 15px
			
			&:first-child
				+breakpoint( tablet )
					display none				

	.user-name
		font-size 16px
		color #818282
		margin 8px 0
		font-weight 100

	.icon-notifications
		display block
		margin 9px 0
		
	.notifications 
		position relative 
		display block
		
		i 
			display inline-block
			margin-top -3px 
		
		.number 
			position absolute
			top -2px
			right -5px
			background #00d3f1
			border 1px solid #818282
			padding 0px 4px
			font-size 9px
			font-weight bold
			color $color-gray

	.btn-login,
	.btn-logout
		font-size 16px
		color #818282
		font-weight 100
		float right
		margin 32px 0 0 30px
		text-decoration underline
		font-family $font-family-base
		
	.btn-logout
		+breakpoint( tablet )
			display block

	&.logged
		.status-logged
			display block		
			
		.btn-login
			display none
			
		& + main
			margin-top -1px

	&.no-logged
		.status-no-logged
			display block
			
		nav
			ul
				margin 29px 22px 25px 0
				
			li 
				width auto
				padding 0
				margin 0 35px 0 0
				border 0
				color #454545
				text-transform none
				
				.form-button
					padding 6px 38px
					font-family $font-family-roboto
					font-size 14px
					font-weight 400
					text-transform none
					border #cccccc				
				
				&:after
					display none

				a 
					font-family $font-family-roboto
					color #454545
					font-size 16px
					font-weight 300
					
					&:active
					&:visited
						color #454545
						
	&.header-register
		.login
			float right
			font-size 16px
			margin-top 28px
			
	.menu-mobile
		padding 25px 0
		
		i
			color #00d3f1
			font-size 30px
			float right
			height 23px
			
		.current-content
			border-bottom 2px solid #00d3f1
			display block
			
		.current
			font-family $font-family-titles
			font-size 18px
			color #226889
			padding-left 20px
			text-transform uppercase
			margin 0 0 10px
			font-weight 700

			
		.list
			display none
			background #00d3f1
			margin -1px 0 0
			padding 30px 20px 0
			list-style none
			width 100%

			li
				padding-bottom 30px
				margin 0
				a 
					color #fff
					font-family $font-family-titles
					font-size 18px
					text-transform uppercase
					text-decoration none
					&:active
					&:visited
						color #fff	
header.ShowMenu
	transition all 800ms ease-in-out
	position fixed
	height 91px
	top 0
	width 100%
	left 0
	z-index 99
	background-color #fff
	padding-bottom 15px
	box-shadow 0 4px 4px -2px rgba( 0, 0, 0, .1 )
	+breakpoint( tablet )
		height 173px	
.ShowMenu.menu-hide
	top -100%	
header.ShowMenu.logged
	position static
	height auto
	box-shadow none

