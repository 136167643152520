@import 'nib'
@import 'utils.styl'

[data-page='my-data']
	padding-top 25px
	
	.my-data-container
		padding 60px 0 60px
		
	.nav-list
		margin-top 50px
		
		li 
			width 33%
			
	.edit
		margin-bottom 30px
		display block
			
	.search-cep
		display block
		margin-top 35px
		
	.pass-1-form-2
		.-blue	
			margin-top 27px
	.title-new-password
		margin-bottom 50px
	.-green
		margin-top 50px
		padding-top 25px
		padding-bottom 25px
		

	.pay-content
	.cepPaciente
	.new-patient-content
	.pass-form
	.pass
		display none 
		&.active
			display block
		
	.checkbox
		margin-top 35px	
		
	.box-button
		margin 50px 0

	.box-patient
		padding 25px 20px 25px 30px
		
		h4
			float left
			color $color-gray
			font-family $font-family-titles
			font-size 24px
			margin 0
			
		.infos
			float right
			padding-top 9px
			
			> *
				margin-left 30px
			
	.status-active
		color #a2ba54
	
	.status-inactive
		color #f14c00
		
	.icon
		display inline-block
		vertical-align middle
		
	.pass-1-form-1-content
		padding-bottom 50px
		
	.partial-edit-patient
		.new-patient-content
			padding 50px 0
			
	.button-location
		.icon-mark
		.icon-unmark
			display none 
		
	.form-button	
		&.-green
			width 100%
		
	.pass-3
		padding-top 50px
		
	.title-pay-form
		margin-top 50px
	.cpf-alert
	.cnpj-alert
		display none
		position absolute
		z-index 1
		top 0
		right -165%	
	.check-isento
		opacity 0
	.form-button.button-charges
		display inline-block
		margin-bottom 15px
		color #818282
		border 0
		text-align left
		font-weight 500
		font-size 16px
		text-transform none
		text-decoration underline !important
		padding 0
		background transparent
		
		&:hover
			background transparent
			
		