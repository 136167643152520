@import 'nib'
@import 'utils.styl'

.loading
	position absolute
	top 0
	left 0
	width 100%
	background rgba(0, 0, 0, 0.3)
	z-index 99999
	
	img 
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		margin auto
		width 100px
		height 100px
