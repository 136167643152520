@import 'nib'
@import 'utils.styl'


body
	&.overflow-body
		position fixed
		width 100%
		height 100%


.modal-bg
	position fixed
	height 100%
	width 100%
	background #000
	background rgba( 0, 0, 0, .8 )
	z-index 2098
	display none
	top 0
	left 0

.modal
	display none
	top -100%
	left 50%
	transform translateX( -50% )
	position fixed
	z-index 2099
	padding 10px 40px 34px
	border-radius 5px
	background-color white
	box-sizing border-box

	.close-modal
		position absolute
		font 35px/22px $font-medium
		right 10px
		color black
		cursor pointer
		background-color transparent
		border 0


.modal-scroll
	position absolute
	top 0
	left 0
	width 100%
	height 100%
	z-index 100000
	overflow-y scroll
	.modal
		position absolute
		margin 40px 0 40px 0
.reveal-modal.ModalAlert
	visibility visible
	opacity 0
	top 15%
	p
		padding-top 50px
		padding-bottom 50px
		text-align center
.bg-modal
	background-color rgba( 0, 0, 0, .3 )
	height 100vh
	width 100%
	top 0
	left 0
	opacity 0
	display none
	position fixed
	z-index 99
.modal-profissional
	.form-group
		text-align right
		textarea
			border 1px solid #03d3ef
		button
			margin-top 25px
			width 165px
			background-color: #03d3ef
			font-family 'Open Sans', sans-serif
			font-weight 700
			border 1px solid #03d3ef
			padding 9px 20px
.bg-modal-payment
	background-color rgba( 0, 0, 0, .3 )
	height 100vh
	width 100%
	top 0
	left 0
	display block
	position fixed
	z-index 99
	display none
	[data-component='modal-payment-change']
		margin-left -369px
		width 650px
		display block
		visibility visible
		background-color #fff
		+breakpoint( mobile )
			position static
			left 0
			margin-left 0px
			width 100%
		.box-modal
			border 2px solid #03d3ef
			padding-bottom 30px
		.box
			padding 5px
			border 2px solid #03d3ef
			cursor pointer
			.box-content-text
				color #000
				font-size 16px
		.box.payment-slip
			+breakpoint( mobile )
				margin-bottom 30px
		.box.active
			background-color #03d3ef
			color #fff
		.box-title
			display block
		.icon-mark
		.icon-unmark
			margin-top 10px
		.fa-credit-card
			margin-right 10px
			font-size 3em
			display inline-block
		.fa-barcode
			margin-right 10px
			font-size 3em
			display inline-block	
		.card-content
		.boleto-content
			margin-top 20px
			display none	
			.-blue
				margin-top 30px
	.close-payment
		color #fff
		font-size 35px
		font-weight 100
		top 16px
		right 14px	
		line-height 0.5
		position absolute
		cursor pointer
		text-decoration none
			
		
