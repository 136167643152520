@import 'nib'
@import '../../../assets/styl/utils.styl'

.user-tip
	.user-tip-header
		background #00d3f1
		color #fff
		font-size 20px
		text-align center
		padding 17px 20px 20px
		
	.icon 
		float right
		margin-top 2px 
		
	.user-tip-body
		background #f1f2f2
		text-align center
		padding 25px 10px 20px
		
	.name-user
		font-size 14px 
		
		strong
			font-size 
			
	.form-button
		background #f44b00
		border-color #f44b00
		font-size 16px 
		font-weight bold
		color #fff
		text-decoration none
		
		&:hover
		&:active
		&:visited
			color #fff
			text-decoration none
		