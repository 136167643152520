@import 'nib'
@import 'utils.styl'

[data-page='nova-senha-profissional']
	background-image url( '../img/login-profissional/bg-new-pass.png' )
	background-repeat no-repeat
	background-position top center
	background-size cover
	.bg-login
		padding 100px 70px 50px 70px
		+breakpoint( mobile )
			padding 50px 5px
		h3
			font-family $font-family-base
			font-weight 700
			font-size 16px 
			color $color-gray
			text-align left
			margin-bottom 25px
		.form-login
			color  $color-gray
			background-color #fff
			padding 28px
			border 2px solid #638c96
			border-radius 5px
			border-bottom 4px solid #03d3ef
			form
				.to-access
					margin-top 50px
					border-top 2px solid #f1f1f1
					text-align center
					padding-top 20px
					a
						color $color-blue
						font-size 16px
						font-family $font-family-base
						font-weight 700
						text-decoration none
			.form-group
				margin 0
				label
					margin-bottom 10px
					margin-top 20px
				input
					padding 14px 19px
					margin-bottom 0
				small
					font-size 14px
					font-family $font-family-base

						