@import 'nib'
@import '../../assets/styl/utils.styl'

[data-partial='footer-profissional']
	padding 20px 0
	figure
			width 188px
			height auto
			margin-left 55px
		img
			width 100%
			height auto
		figcaption
			color $color-gray
			font-family $font-family-base
			font-size 12px
	span
		margin 0 auto
		width 60%
		height 1px
		background #ffffff
		background linear-gradient( to right, #ffffff 0%, #818282 51%, #ffffff 100% )
		display block
	p
		padding 20px
		text-align center
		font-family $font-family-titles
		font-weight 700
		font-size 10px
		color $color-gray
	.menu-bottom
		margin-bottom 15px
		+breakpoint( mobile )
			display none
		ul
			display inline-block
			list-style none
			li
				a
					color $color-gray
					font-size 12px
					font-family $font-family-base
		