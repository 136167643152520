@import 'nib'
@import '../../assets/styl/utils.styl'

footer
	border-top solid 1px #ccc
	padding-top 35px
	
	+breakpoint( tablet )
		border 0
	
	.status
		display none
	
	&.logged
		.status-logged
			display block
			
		.logo
			width 80px
			margin 0 auto
			display block

		h3
			margin 0
			font-size 36px
			text-align center
			font-family $font-family-titles
			font-weight 100
			text-transform uppercase
			margin-bottom 30px

		.copyright,
		.cnpj
			color #818282
			text-align center
			font-size 10px
			font-weight 600
			display block
			font-family $font-family-titles

		.cnpj
			margin-bottom 5px

		.tt-tel,
		.tel
			color #818282
			font-weight 600
			display block
			font-family $font-family-titles
			font-size 10px

			&:hover
				text-decoration none

		.tt-tel
			font-size 10px

		.tel
			font-size 18px

	&.no-logged
		background #266988
		padding 45px 0
		
		+breakpoint( tablet )
			background #fff
			padding 0
		
		.status-no-logged
			display block
		
		h3
			font-family $font-family-roboto
			font-weight 900
			font-size 16px
			color #fff
			margin 0
			text-transform none
			text-align left
			
			span 
				color #53bfeb
				font-size 20px
				margin 5px 0 0
				display block

		h4
			font-family $font-family-roboto
			font-weight 400
			font-size 14px
			color #fff
			margin 15px 0 0
			text-align center
			
		.logo
			clearfix()
			width auto
			margin 0
			
			img 
				float right
				
	&.footer-register
		border 0
		padding 0
		text-align center
		
		+breakpoint( tablet )
			text-align left
		
		.container
			padding-top 35px
			
		.logo
			margin-bottom 30px
		
		ul 
			padding 0
			list-style none
			margin 0

		a
			color $color-gray
			text-decoration none
			
			&:hover
			&:active
			&:visited
				color $color-gray
				text-decoration none
			
		p 
			font-size 10px
			font-weight 700
			color $color-gray
			
	.footer-mobile
		text-align center
		
		hr 
			margin 0
		
		p
			margin 20px auto
		
		.logo 
			display block
			margin 20px auto
			
		.logo-description
			font-size 11.15px
			font-weight 400