@import 'nib'
@import '../../../assets/styl/utils.styl'
$border = 5px

.table-row-mobile-financial-professional
	text-align center
	border 1px solid #ebebeb
	border-top-left-radius $border
	border-top-right-radius $border
	border-bottom-left-radius 10px
	border-bottom-right-radius 10px
	
	.table-row-mobile-financial-professional-header
		border-top-left-radius $border
		border-top-right-radius $border
		
		&.scheduled
			background #fbb731

		&.paid
			background #a2ba54
			
		h2
			font-family $font-family-titles
			color #fff
			text-transform uppercae
			font-size 24px 
			font-weight 700
			padding 5px 
			margin 0
			
	.table-row-mobile-financial-professional-body
		padding 19px 15px
		
	p 
		color $color-gray
		font-size 14px
		margin 0
		line-height 24px
		
		&.upper 
			font-family $font-family-titles
			font-size 24px
		
	.btn-action
		&.canceled 
			color #f44b00
			
	.align 
		text-align left 
		padding-left 15px 
		padding-top 10px
		
		a 
			display block
			
		i 
			margin-right 5px