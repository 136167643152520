@import 'nib'
@import 'utils.styl'

[data-page='login-profissional']
	background-image url( '../img/login-profissional/bg-login.png' )
	background-repeat no-repeat
	background-position top center
	background-size cover
	.bg-login
		padding 100px 70px 50px 70px
		+breakpoint( mobile )
			padding 50px 5px
		h3
			font-family $font-family-base
			font-weight 700
			font-size 16px 
			color $color-gray
			text-align center
			margin-bottom 25px
		.form-login
			color  $color-gray
			background-color #fff
			padding 28px
			border 2px solid #638c96
			border-radius 5px
			border-bottom 4px solid #03d3ef
			form
				.to-access
					border-top 2px solid #f1f1f1
					text-align center
					padding-top 20px
					a
						color $color-blue
						font-size 16px
						font-family $font-family-base
						font-weight 700
						text-decoration none
			.form-group
				label
					margin-bottom 10px
				input
					padding 14px 19px
			.forgot-password
				margin-bottom 40px
				span
					position absolute
					width 100%
					bottom -2px
					height 1px
					background-color #a2d8ef 
				a
					position relative
					border-bottom 1px solid #428bca
					text-decoration none
					font-style italic
					&:hover
						color #428bca
							span
						