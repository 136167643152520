@import 'nib'
@import '../../../assets/styl/utils.styl'

.table-result
	margin 50px 0
		
	.tooltip-form.-out
		z-index -100

	.results
		font-size 16px
		color $color-gray
		margin-bottom 20px
		display inline-block
		padding-right 18px

	.title
		text-transform uppercase
		font-family $font-family-titles
		color $color-gray
		font-size 24px
		font-weight 300
		padding 0 10px
		padding-bottom 12px
		text-align center
		
		+breakpoint( mobile )
			font-size 16px

	.table-list
		margin 0
		padding 0
		list-style none
		
		> .row		
			> div
				padding 0 17px
				+breakpoint( mobile )
					left -55px
				
	#atendimentos
		margin-bottom 30px
		padding 0 15px
		
		> .row
			min-width 1140px
		
		+breakpoint( tablet )
			overflow-x scroll

	.table-item
		min-height 100px
		border-left solid 10px
		border-right solid 10px
		border-radius 7px
		
		> div
			padding-top 40px
		.date
		.status
		.patient
		.professional
		.action
			text-align center		
			
		.professional
			padding-top 25px
			
			p 
				vertical-align middle
				
		&.dark
			background-color #F3F3F3
			
		&.blue
			background-color #d9f8fd

		&.scheduled
			border-color $color-yellow

			.btn-action
				color $color-yellow

		&.completed
			border-color $color-green

		&.andamento
			border-color #54baa8

		&.canceled
			border-color $color-orange

			.btn-action
				color $color-orange
		&.waiting
			border-color #8B4513
			
			.btn-action
				color #8B4513		
		> div
			display table-cell
			vertical-align middle

		td
			padding 26px 10px
			vertical-align top

		img
			margin-right 15px

		b
			color $color-gray
			font-size 16px
			display block

		p
			max-width 275px
			font-size 14px
			color $color-gray
			display inline-table
			margin 0
			line-height 130%
			vertical-align top

/*	.status
		width 13%

	.patient
		width 20%

	.professional
		width 32%

	.date
		width 12%

	.action
		width 23%*/

	.btn-action
		font-family $font-family-base
		font-style italic
		font-size 14px
		color $color-gray
		text-decoration underline

		.icon
			display inline-block
			margin-right 5px
			position relative
			top 3px


	.link-style
		display inline-block
		
		i 
			display inline-block
			vertical-align middle
			margin-right 5px
			
	.link-details
		color #03d3ef
		font-weight bold
		font-size 14px	
		font-family $font-family-titles
		text-decoration underline	
		
		i 
			display inline-block
			vertical-align middle
			margin-right 10px
			margin-left -3px


.table-result-financial
	.status
		width 10%

	.value
		width 11%

	.type-payment
		width 21%
		
	.date
		width 12%
		
	.resume
		width 22%
				
	.list-title
		margin 2px 0 7px
		font-size 14px
		color $color-gray
		
	.list-attendance
		list-style none
		padding 0
		font-weight bold
		color $color-gray
		font-size 14px
		margin-bottom 0
		width 94%
		
		li
			clearfix()
			margin-bottom 4px
			position relative
			
		hr
			position absolute
			z-index 10
			border-top 1px dotted #000
			width 100%
			margin 0
			bottom 5px
		
	.speciality
		float left
		padding-right 10px
		
	.number
		float right
		padding-left 10px

	.speciality
	.number
		background-color #fff
		position relative
		z-index 20
		
	.dark
		.number
		.speciality
			background-color #f3f3f3
			
	.list-action
		padding 0
		list-style none
		margin 0

	
		li
			margin-bottom 10px 
		
		i
			margin-right 8px
			
	.list-action-link 
		color $color-gray
		font-size 14px
		
		&:hover
		&:active
		&:visited
			color $color-gray
			
	.row
		> div
			padding-bottom 10px !important
			padding-top 10px !important
			display inline-block
			vertical-align top
			
.table-result-financial-details
	position relative
	
	.patient
		width 25%
	
	.table-item
		border 0
		
	.professional
		width 44%
		
	.date
		width 15%

	.table-item
		font-size 14px
		
		&.blue		
			vertical-align top 
			padding-top 16px 
			padding-bottom 39px
			border-top 3px solid #03d3ef
			border-radius 0
			border-bottom-left-radius 10px
			border-bottom-right-radius 10px 
				
	.row
		> div
			display inline-block
			vertical-align top 
			
			&:first-child
				padding-left 50px
				
				+breakpoint( mobile )
					padding-left 0
