@import 'nib'
@import 'utils.styl'

[data-page='profissional-financeiro']
	.money-order-body
		display none
	.table-result
		margin 20px 0 
		.type-payment
			width 10%
		.date
			width 19%
		.resume
			width 24%
		.pending
			border-left solid 10px #fbb731
			border-right solid 10px	#fbb731	
		.already-paid
			border-left solid 10px #a2ba54
			border-right solid 10px	#a2ba54	
			
	.table-result-financial
		.list-action-link
			color #fff
			border-radius 5px
			padding 12px 28px
			height auto
			display block
			text-decoration none
			&:hover
			&:active
			&:visited
				color #fff
				text-decoration none
		.-accept
			background-color #9fb459
			border 1px solid #9fb459
			color #fff
			&:hover
				background-color #fff
				color #9fb459	
				border 1px solid #9fb459			
		.-cancel
			margin-top 9px
			background-color #e6520d
			border 1px solid #e6520d
			color #fff
			&:hover
				background-color #fff
				color #e6520d		
				border 1px solid #e6520d	
	.table-result-financial-details 
		.row > div
			margin-bottom 30px
		.financial-border-bottom
			border-radius 0
			border-bottom 2px solid #03d3ef
		.financial-detail-attendance
			padding-top 0px !important
			div
				padding 0
				margin 0
		.row.table-item
			min-height 20px
			height auto
			div
				margin-bottom 5px
				margin-top 20px
				padding 10px
				
			.-detail
				font-size 14px
				color $color-gray
				font-family $font-family-base
				padding 0 5px
				+breakpoint( mobile )
					left -50px
				&:first-child
					padding-left 50px
					+breakpoint( mobile )
						left -70px
				&:nth-child( 2 )
					font-weight 700	
				.icon-arrow-down-green
					display inline-block
				.icon-arrow-down-green.icon-arrow-green
					transform rotateX( 180deg )
		.atendimentolabel
			+breakpoint( mobile )
				display none
		.row.table-item.blue-footer
			border-bottom none
			border-top none
			padding 10px 5px 10px 50px	
			min-height: 20px
			height auto
			border-radius 0
			background-color #d9f8fd
			div
				padding 0
				color $color-gray
			.text-right
				padding-right 5px
				+breakpoint( mobile )
					left -100px
			.value-footer
				padding 0
				color $color-gray
			.footer-total
				text-align right	
				padding-right 83px		
		.row.table-item.blue
			border-bottom none
			border-top none
			padding 10px 0
			+breakpoint( mobile )
				display none
			div
				margin-top 0
				font-size 20px
				color $color-gray
				font-family $font-family-titles
				text-transform uppercase
	.modal-reveal
		.table-list
			.row
				div
					padding 0 5px
				&:first-child
					padding-left 50px	



			// .list-action-link:hover, .table-result-financial .list-action-link:active, .table-result-financial .list-action-link:visited