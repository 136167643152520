/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-academic-data-white {
  background-image: url(../img/sprite.png);
  background-position: -42px -351px;
  width: 43px;
  height: 27px;
}
.icon-academic-data {
  background-image: url(../img/sprite.png);
  background-position: -85px -351px;
  width: 40px;
  height: 25px;
}
.icon-acupuncture {
  background-image: url(../img/sprite.png);
  background-position: -257px -132px;
  width: 53px;
  height: 60px;
}
.icon-agenda {
  background-image: url(../img/sprite.png);
  background-position: -410px 0px;
  width: 30px;
  height: 30px;
}
.icon-alert {
  background-image: url(../img/sprite.png);
  background-position: -374px -109px;
  width: 35px;
  height: 32px;
}
.icon-arrow-down-green {
  background-image: url(../img/sprite.png);
  background-position: -153px -82px;
  width: 20px;
  height: 11px;
}
.icon-arrow-down {
  background-image: url(../img/sprite.png);
  background-position: -410px -335px;
  width: 30px;
  height: 16px;
}
.icon-arrow-up-green {
  background-image: url(../img/sprite.png);
  background-position: -133px -82px;
  width: 20px;
  height: 11px;
}
.icon-arrow-up {
  background-image: url(../img/sprite.png);
  background-position: -410px -351px;
  width: 30px;
  height: 16px;
}
.icon-asked-question {
  background-image: url(../img/sprite.png);
  background-position: -374px -244px;
  width: 33px;
  height: 30px;
}
.icon-attachment {
  background-image: url(../img/sprite.png);
  background-position: -238px -169px;
  width: 15px;
  height: 17px;
}
.icon-attendance-small {
  background-image: url(../img/sprite.png);
  background-position: -336px -295px;
  width: 30px;
  height: 31px;
}
.icon-attendance {
  background-image: url(../img/sprite.png);
  background-position: -223px -234px;
  width: 50px;
  height: 52px;
}
.icon-avatar {
  background-image: url(../img/sprite.png);
  background-position: -142px -295px;
  width: 46px;
  height: 45px;
}
.icon-big-green-circle-verify {
  background-image: url(../img/sprite.png);
  background-position: -61px -234px;
  width: 61px;
  height: 61px;
}
.icon-blue-circle-verify {
  background-image: url(../img/sprite.png);
  background-position: 0px -234px;
  width: 61px;
  height: 61px;
}
.icon-blue-close {
  background-image: url(../img/sprite.png);
  background-position: -410px -240px;
  width: 26px;
  height: 25px;
}
.icon-blue-location-pin {
  background-image: url(../img/sprite.png);
  background-position: -374px -310px;
  width: 28px;
  height: 34px;
}
.icon-blue-verify {
  background-image: url(../img/sprite.png);
  background-position: -125px -351px;
  width: 31px;
  height: 25px;
}
.icon-brain {
  background-image: url(../img/sprite.png);
  background-position: -257px 0px;
  width: 57px;
  height: 66px;
}
.icon-calendar {
  background-image: url(../img/sprite.png);
  background-position: -374px -141px;
  width: 36px;
  height: 31px;
}
.icon-circle-green-arrow-down {
  background-image: url(../img/sprite.png);
  background-position: -102px 0px;
  width: 82px;
  height: 82px;
}
.icon-clock {
  background-image: url(../img/sprite.png);
  background-position: -314px -62px;
  width: 60px;
  height: 55px;
}
.icon-close-x {
  background-image: url(../img/sprite.png);
  background-position: -410px -291px;
  width: 22px;
  height: 22px;
}
.icon-close {
  background-image: url(../img/sprite.png);
  background-position: -410px -386px;
  width: 20px;
  height: 19px;
}
.icon-code-security {
  background-image: url(../img/sprite.png);
  background-position: -184px -123px;
  width: 72px;
  height: 36px;
}
.icon-crutches {
  background-image: url(../img/sprite.png);
  background-position: -78px -102px;
  width: 66px;
  height: 66px;
}
.icon-data {
  background-image: url(../img/sprite.png);
  background-position: -102px -82px;
  width: 16px;
  height: 16px;
}
.icon-details {
  background-image: url(../img/sprite.png);
  background-position: -410px -313px;
  width: 22px;
  height: 22px;
}
.icon-file-profile {
  background-image: url(../img/sprite.png);
  background-position: -374px -274px;
  width: 27px;
  height: 36px;
}
.icon-filter-closed {
  background-image: url(../img/sprite.png);
  background-position: -295px -209px;
  width: 16px;
  height: 17px;
}
.icon-filter {
  background-image: url(../img/sprite.png);
  background-position: -295px -192px;
  width: 16px;
  height: 17px;
}
.icon-financial-small {
  background-image: url(../img/sprite.png);
  background-position: -374px -37px;
  width: 36px;
  height: 36px;
}
.icon-financial {
  background-image: url(../img/sprite.png);
  background-position: -46px -295px;
  width: 50px;
  height: 50px;
}
.icon-gestation {
  background-image: url(../img/sprite.png);
  background-position: -144px -102px;
  width: 38px;
  height: 65px;
}
.icon-green-circle-verify {
  background-image: url(../img/sprite.png);
  background-position: -226px -295px;
  width: 37px;
  height: 37px;
}
.icon-hand {
  background-image: url(../img/sprite.png);
  background-position: 0px -102px;
  width: 78px;
  height: 67px;
}
.icon-key {
  background-image: url(../img/sprite.png);
  background-position: -299px -295px;
  width: 37px;
  height: 36px;
}
.icon-location-active {
  background-image: url(../img/sprite.png);
  background-position: -191px -169px;
  width: 47px;
  height: 56px;
}
.icon-location-availability {
  background-image: url(../img/sprite.png);
  background-position: -410px -210px;
  width: 24px;
  height: 30px;
}
.icon-location {
  background-image: url(../img/sprite.png);
  background-position: -176px -234px;
  width: 47px;
  height: 56px;
}
.icon-logo {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 102px;
  height: 102px;
}
.icon-mark {
  background-image: url(../img/sprite.png);
  background-position: -257px -192px;
  width: 38px;
  height: 38px;
}
.icon-microscope {
  background-image: url(../img/sprite.png);
  background-position: -257px -66px;
  width: 49px;
  height: 66px;
}
.icon-notices {
  background-image: url(../img/sprite.png);
  background-position: -122px -234px;
  width: 54px;
  height: 58px;
}
.icon-notifications-circle-new {
  background-image: url(../img/sprite.png);
  background-position: -96px -295px;
  width: 46px;
  height: 46px;
}
.icon-notifications-circle {
  background-image: url(../img/sprite.png);
  background-position: -374px 0px;
  width: 36px;
  height: 37px;
}
.icon-notifications {
  background-image: url(../img/sprite.png);
  background-position: -410px -265px;
  width: 24px;
  height: 26px;
}
.icon-perfil {
  background-image: url(../img/sprite.png);
  background-position: -263px -295px;
  width: 36px;
  height: 37px;
}
.icon-person1 {
  background-image: url(../img/sprite.png);
  background-position: -410px -150px;
  width: 24px;
  height: 30px;
}
.icon-person2 {
  background-image: url(../img/sprite.png);
  background-position: -410px -120px;
  width: 24px;
  height: 30px;
}
.icon-person3 {
  background-image: url(../img/sprite.png);
  background-position: -410px -90px;
  width: 24px;
  height: 30px;
}
.icon-person4 {
  background-image: url(../img/sprite.png);
  background-position: -410px -180px;
  width: 24px;
  height: 30px;
}
.icon-person5 {
  background-image: url(../img/sprite.png);
  background-position: -374px -208px;
  width: 30px;
  height: 36px;
}
.icon-person6 {
  background-image: url(../img/sprite.png);
  background-position: -374px -172px;
  width: 30px;
  height: 36px;
}
.icon-person7 {
  background-image: url(../img/sprite.png);
  background-position: 0px -295px;
  width: 46px;
  height: 56px;
}
.icon-phone-night-day {
  background-image: url(../img/sprite.png);
  background-position: 0px -169px;
  width: 61px;
  height: 65px;
}
.icon-physiotherapy {
  background-image: url(../img/sprite.png);
  background-position: -61px -169px;
  width: 62px;
  height: 62px;
}
.icon-plus-circle {
  background-image: url(../img/sprite.png);
  background-position: -118px -82px;
  width: 15px;
  height: 15px;
}
.icon-professional-experience-white {
  background-image: url(../img/sprite.png);
  background-position: 0px -381px;
  width: 39px;
  height: 29px;
}
.icon-professional-experience {
  background-image: url(../img/sprite.png);
  background-position: 0px -351px;
  width: 42px;
  height: 30px;
}
.icon-register {
  background-image: url(../img/sprite.png);
  background-position: -410px -60px;
  width: 28px;
  height: 30px;
}
.icon-seal-verify {
  background-image: url(../img/sprite.png);
  background-position: -314px -239px;
  width: 56px;
  height: 54px;
}
.icon-search {
  background-image: url(../img/sprite.png);
  background-position: -410px -367px;
  width: 20px;
  height: 19px;
}
.icon-specialty {
  background-image: url(../img/sprite.png);
  background-position: -410px -30px;
  width: 30px;
  height: 30px;
}
.icon-speech-therapy {
  background-image: url(../img/sprite.png);
  background-position: -314px -172px;
  width: 49px;
  height: 67px;
}
.icon-status {
  background-image: url(../img/sprite.png);
  background-position: -273px -234px;
  width: 34px;
  height: 47px;
}
.icon-suitcase {
  background-image: url(../img/sprite.png);
  background-position: -123px -169px;
  width: 68px;
  height: 56px;
}
.icon-unmark {
  background-image: url(../img/sprite.png);
  background-position: -188px -295px;
  width: 38px;
  height: 38px;
}
.icon-warning-board {
  background-image: url(../img/sprite.png);
  background-position: -374px -73px;
  width: 34px;
  height: 36px;
}
.icon-white-attendance {
  background-image: url(../img/sprite.png);
  background-position: -314px 0px;
  width: 60px;
  height: 62px;
}
.icon-white-clock {
  background-image: url(../img/sprite.png);
  background-position: -314px -117px;
  width: 60px;
  height: 55px;
}
.icon-white-eye {
  background-image: url(../img/sprite.png);
  background-position: -184px 0px;
  width: 73px;
  height: 58px;
}
.icon-white-home {
  background-image: url(../img/sprite.png);
  background-position: -184px -58px;
  width: 65px;
  height: 65px;
}
