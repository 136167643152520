@import 'nib'
@import '../../../assets/styl/utils.styl'
$border = 5px

.table-row-mobile
	text-align center
	border 1px solid #ebebeb
	border-top-left-radius $border
	border-top-right-radius $border
	border-bottom-left-radius 10px
	border-bottom-right-radius 10px
	
.table-row-mobile-header
		border-top-left-radius $border
		border-top-right-radius $border
		
		&.scheduled
			background #fbb731
			
		&.andamento
			background #54baa8

		&.canceled
			background #f14c00

		&.protested
			background #000

		&.unsucessful
			background #f14c00

		&.paid
			background #a2ba54
			
		&.completed
			background #a2ba54
		&.waiting
			background #8b4513
		h2
			font-family $font-family-titles
			color #fff
			text-transform uppercae
			font-size 24px 
			font-weight 700
			padding 5px 
			margin 0
			
	.table-row-mobile-body
		padding 19px 15px
		
	p 
		color $color-gray
		font-size 14px
		margin 0
		line-height 24px
		
		&.upper 
			font-family $font-family-titles
			font-size 24px
		
	.btn-action
		&.canceled 
			color #f44b00
			
	.align 
		text-align left 
		padding-left 15px 
		padding-top 10px
		
		a 
			display block
			
		i 
			margin-right 5px