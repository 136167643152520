@import 'nib'
@import 'utils.styl'

[data-page='cadastro-profissional-status-envio']
	.home
		.block-highlight
			padding 35px 18px 35px
			border 1px solid #03d3ef
			border-top 4px solid #03d3ef
			border-top-left-radius 5px
			border-top-right-radius 5px
			.icon
				height 70px
				text-align center
				margin-bottom 11px
				i
					display inline-block
					vertical-align middle	
			h3
				color #818282
				font-family 'Open Sans Condensed', sans-serif
				font-size 30px
				text-transform uppercase
				text-align center
			p
				color #818282
				font-family 'Open Sans Condensed', sans-serif
				font-size 20px
				text-align center
	i.icon-asked-question
		display block
		margin 0 auto !important
		margin-top 50px !important
		margin-bottom 15px !important
						