@import 'nib'
@import 'utils.styl'

[data-page="agenda"]
	.btn-filter-mobile
		border 1px solid #03a7e0
		border-radius 4px
		width 100%
		color $color-white
		background-color #03a7e0
		padding 12px 5px
		font-family $font-family-base
		text-transform uppercase
		font-size 16px
		font-weight 700
		margin-bottom 20px
		box-shadow none
		outline 0
		&:focus
			box-shadow none
			outline 0			
		&:active
			box-shadow none	
			outline 0		
		.icon-filter
		.icon-filter-closed
			display inline-block
			margin-right 5px
			margin-bottom -2px
		
	.btn-filter-mobile.filter-opened
		color #03a7e0
		background-color $color-white
		border 1px solid #03a7e0
		box-shadow none	
	// .filter-mobile		
	// 	display none
	.reveal-modal.agenda
		width 600px
		left 50%
		margin-left -300px	
		+breakpoint( mobile )
			width 100%
			left 0
			margin-left 0
		.title
			margin 0
			margin-bottom 20px
			h2
				font-size 30px
		h3
			font-size 16px
			color $color-gray
			margin-bottom 6px
		.button-specialty
			margin-bottom 30px
			padding 22px 12px
		.box
			display inline-block
			width 100%
			position relative
			border 2px solid #03d3ef
			border-bottom 5px solid #03d3ef
			border-radius 5px
			background #fff
			background-clip padding-box
			text-decoration none
			vertical-align middle
			cursor pointer	
			.icon-top
				padding 0
				display inline-block
				.icon-calendar
					margin 0
					margin-right 20px
			.icon-bottom
				width 70%
				display inline-block
			.icon-bottom.inative
				display none
			.icon-bottom.active
				opacity 1
				display inline-block
				transition display 2s ease-in-out
			&:before
				position absolute
				top -2px
				bottom -2px
				left -2px
				right -2px	
				background linear-gradient( to top, #03d3ef, #a9f0fa )
				content ' '
				z-index -1
				border-radius 5px
		.box.active
			background #03d3ef
			border-color #03d3ef
	
	.title
		margin 30px 0 30px

	p
		padding 10px 60px
		margin 0
		color $color-gray
		font-size 14px
		font-family $font-family-base
	.icon-blue-close
		display inline-block
	select.form-control
		font-style italic
	.calendar
		margin-top 50px
		// background-color #ededed
		padding-top 15px
		border-bottom 7px solid #03d3ef
		border-bottom-left-radius 12px
		border-bottom-right-radius 12px
		button 
			display block
			text-shadow none
			box-shadow none
			border-color none
			background-image none	
			font-family $font-family-base
			text-transform uppercase		
		.fc-toolbar.fc-header-toolbar
			margin-left 53px
			background-color #ededed
			margin-bottom 0
			padding-top 20px
			padding-bottom 20px
			border-top-left-radius 12px
			border-top-right-radius 12px	
			+breakpoint( mobile )
				margin-left 0	
			.fc-left
				.fc-tarefa-button
					margin-left 30px
					background-color #03d3ef
					color #fff
					text-shadow none
					box-shadow none
					border-color none
					background-image none
					+breakpoint( mobile )
						margin-left 100px
				.fc-button
					height 40px
					width 190px
					color #fff
					font-size 16px
					font-weight 700
					font-family $font-family-titles	
					+breakpoint( mobile )
						margin-bottom 20px				
			.fc-right
				.fc-button-group
					display block
					margin-right 30px
					background-color #e1e1e1
					color #fff
					.fc-button
						height 40px
						color $color-gray
						width 140px	
						background-color #e1e1e1
						font-size 16px
						font-weight 700
						font-family $font-family-titles	
						+breakpoint( mobile )
							display none
				.fc-button.fc-state-active
					background-color #03d3ef
					color #fff

			.fc-center
				.fc-next-button
				.fc-prev-button
					border none
					background-color #ededed
					color #fff
					.fc-icon
						color #266988
				.fc-button
					height 40px
					color #fff
				h2
					color #266988
					text-transform uppercase
					font-family $font-family-titles
					font-size 30px
					font-weight 700
	.fc
		th
			background-color #266988 !important
			color #fff
			padding 15px 26px
			border none
			border-top-left-radius 12px
			border-top-right-radius 12px
			+breakpoint( mobile )
				padding 0
				border-radius 0
				background-color #fff  !important
				color #fff !important
				padding 10px
				a
					color #266988 !important
			span
				text-transform uppercase
		td
			+breakpoint( mobile )
				border none
	.fc-day-grid
		.fc-row
			background-color #ededed
	.fc-head-container.fc-widget-header
		border none
		background-color #fff
	.fc-row.fc-widget-header
		background-color #ededed !important
		border none
		a
			color #fff
			text-transform uppercase
			+breakpoint( mobile )
				font-size 12px
				font-weight 400
	.icon-verify
		display none
		position absolute
		left -22px
		top 3px
		z-index -1
	.checkbox.day-choose
		margin 0
	.checkbox
		color #03d3ef
		font-size 16px
		position relative
		font-style italic
		cursor pointer
		text-indent 22px
		&[disabled]
			&::before
				background-color #eee !important
				
			input
				display none
			
		&::before
			content ' '
			position absolute
			width 16px
			height 16px
			border 1px solid #03a7e0
			left 0
			top 0
			bottom 0
			margin auto
			
		.icon-verify
			display none
			position absolute
			left -22px
			top 3px
			z-index 1

		input
			opacity 0
			width 100%
			height 100%
			position absolute
			left 20px
			margin 0
			
			&:checked + .icon-verify
				display block
	.icon-calendar.date
		margin-top 28px
	.fc-content-skeleton
		color #000
	.fc-body
		background-color #fff
		+breakpoint( mobile )
			border 1px solid #03d3ef
	.fc-axis.fc-widget-header
		width 1px !important
		background-color #fff !important
		border-radius 0
	.fc-day-grid.fc-unselectable
		background-color #fff
	.fc-day-grid.fc-unselectable
		background-color #fff
	.fc-bg
		background-color #fff
	.fc-event-container
		border-radius none	
	.btn-save-task
		background-color #03d3ef
		font-family 'Open Sans', sans-serif
		font-weight 700
		border 1px solid #03d3ef
		padding 15px 25px
		margin 0 auto
		color #fff
		display block
		width 40%
		+breakpoint( mobile )
			padding 15px 5px
	.task-detail
		font-size 16px
		font-family $font-family-base
		color $color-gray
		margin-bottom 5px
	.edit-task
		font-size 16px
		font-style italic
		text-decoration underline
		color #e6520d
	.no-edit-task
		font-size 16px
		font-style italic
		text-decoration underline
		color #e6520d	
		cursor auto	
	.fa.fa-pencil
		color #e6520d
		margin-left 10px
	.-top-
		padding-top 20px
	.maps
		width 100%
		overflow hidden
	.changeDate
		display none
		padding-top 20px
		padding-bottom 20px
		position absolute
		z-index 99999
		border 2px solid #03d3ef
		background-color #fff
		left 10%
		top 180px
		width 80%
		border-radius 5px
		.btn-alter-task
			background-color #03d3ef
			font-family 'Open Sans', sans-serif
			font-weight 700
			border 1px solid #03d3ef
			padding 5px 10px
			margin 0 auto
			color #fff
			display block
			width 40%
		.close-alter-data
			font-size 35px
			font-weight 100
			top 9px
			right 14px
			line-height 0.5
			position absolute
			color #aaa
			text-shadow 0 -1px 1px rbga( 0, 0, 0, 0.6 )
			cursor pointer  
	.border-event
		border 2px solid yellow
		
	.background-event
		background-color #fbb731
	.realizado
		background-color #fff
		border 1px solid #a2ba54
		color #818282
		border-radius 0
		border-color #a2ba54 !important	
		border-left 10px solid #a2ba54	
		padding-left 5px
		+breakpoint( mobile )
			width 100%

	.andamento
		background-color #fff
		border 1px solid #54baa8
		color #818282
		border-radius 0
		border-color #54baa8 !important	
		border-left 10px solid #54baa8	
		padding-left 5px
		+breakpoint( mobile )
			width 100%

	.agendado
		background-color #fff
		border 1px solid #fbb731
		color #818282
		border-radius 0
		border-color #fbb731 !important
		border 1px solid #fbb731
		border-left 10px solid #fbb731	
		padding-left 5px
		+breakpoint( mobile )
			width 100%			
	.cancelado	
		background-color #fff
		border 1px solid #f14c00
		color #818282
		border-radius 0
		border-color #f14c00 !important
		border-left 10px solid #f14c00	
		padding-left 5px	
		+breakpoint( mobile )
			width 100%			
	.paciente
		display block
	.fc-event
		background-color #fff
		border 0
		height 32px !important
		left 0 !important
		width 100% !important
		cursor pointer
		&:hover
			color #818282	
	.fc-content
		.fc-time
			display none	
	.fc-axis
		font-family $font-family-titles
		font-size 14px
		color $color-gray
		font-weight 700	
		height 30px
		border 0
		border-bottom 1px solid #ddd	
		border-top 1px solid #ddd
		+breakpoint( mobile )
			display none
	.fc-slats
		table
			tbody
				tr
					height 32px	
	form
		p
			font-size 16px
			padding 10px 15px
	.fc-month-view
		.fc-today
			background #ededed 
		.fc-event
			height 40px !important
	.fc-agendaWeek-view
		.fc-today
			background #fff
	
	.fc-day-header.fc-today
		background #03d3ef !important
		a
			+breakpoint( mobile )
				color #fff	!important
	.fc-day-header.fc-future
		background #266988 !important
		a
			+breakpoint( mobile )
				color #fff	!important	
	.fc-day-header.fc-past
		background #266988 !important
		a
			+breakpoint( mobile )
				color #fff	!important	
	tr:first-child
		>td
			>.fc-day-grid-event
				background-color transparent
				.fc-content
					white-space inherit
	.fc-agendaDay-view
		.fc-day
			background-color #fff
		.fc-content
			height 95%
	.bg-agenda
		z-index 5
		top 0
		position absolute
		width 100%
		height 100%
		background-color rgba(0, 0, 0, 0.8)	
		visibility visible
		display none
	.fc-unthemed
		td.fc-today
			+breakpoint( mobile )
				background-color #fff
		
			
		
		

		
				
