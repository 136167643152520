@import 'nib'
@import 'utils.styl'

[data-page='cadastro-profissional-resumo']
	.-top
		margin-top 30px !important
	h2
		margin 0 0 0 50px
	.blue-border
		border 1px solid #00d3f1
		border-radius 5px
		width 100%
		margin-top -20px
		padding 40px
		display flex
		.icon-status
			display block
			margin 0 10px 0 0
		p
			color #818282
			font-size 16px
			font-family $font-family-base
			margin 0 10px
			span
				font-weight 700
		.btn-complete
			background-color #03d3ef
			border 1px solid #03d3ef
			font-weight 700
			padding 14px 30px
			font-size 16px
	.box .-title
		min-height 140px
		margin-top 107px
		margin-bottom 50px
		text-align center
		color #818282
		cursor auto
	.box
		display: inline-block
		width 100%
		position relative
		border 2px solid transparent
		border-radius 5px
		background #fff
		-webkit-background-clip padding
		-moz-background-clip padding
		background-clip padding-box
		padding 12px
		text-decoration none
		vertical-align middle
		margin-top 50px
		&:before
			position absolute
			top -2px
			bottom -2px
			left -2px
			right -2px
			background linear-gradient( to top, #03d3ef, #a9f0fa )
			content ' '
			z-index -1
			border-radius 5px	
		.title-header
			margin-top -30px
			text-align center
			h2
				margin 0 auto
				background #fff
				display inline-block
				padding 0 20px
				font-family 'Open Sans Condensed', sans-serif
				text-transform uppercase
				margin-bottom 20px	
				color #818282
				font-size 28px
		.-items
			text-transform uppercase
			font-family 'Open Sans Condensed', sans-serif
			font-size 22px	
			color #818282
			p
				font-size 20px
				font-family $font-family-base
				text-transform none
				padding 25px 30px
				margin 0
			.RegisterData
				text-align center
				position relative
				h4
					font-family $font-family-titles	
					font-size 30px
					color #03d3ef	
					position absolute
					top 0
					bottom 0
					left 0
					right 0
					top 20px
			h4
				.fa.fa-check
					color #a2ba54
				.fa.fa-close
					color #f14c00
				.icon-blue-verify
				.icon-blue-close
					display inline-block
	.modal-resumo
		background-color #fff
		width 428px
		top 160px
		&:before
			content ' '
			position absolute
			width 15px
			height 15px
			border-bottom 15px solid transparent
			border-right 15px solid #00d3f1
			border-top 15px solid transparent
			left -14px
			top 30px	
		.box
			margin-top 0
			h2
				margin 0 auto
			p
				font-size 14px
			.highlight
				font-size 18px
				text-align center
			.modal-footer
				text-align center
				border none
				padding 0
				margin-top 28px
				a
					display inline-block
					font-family $font-family-base
					font-size 16px
					font-weight 600
					padding 15px 40px
				a.cancel
					background-color #fff
					color $color-blue
					border 1px solid $color-blue
					margin-right 13px
	.modal-resumo.tips-sent
		width 490px
		top 170px
		&:before
			display none
		.box
			p
				font-size 16px
	.modal-resumo.tips-see	
		&:before
			display none				
		.box
			p
				text-align center
			.modal-footer
				a
					padding 15px 18px