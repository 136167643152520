@import 'nib'
@import 'utils.styl'

[data-partial='menu-cadastro-profissional']
	margin-bottom 30px
	margin-top 50px
	.form-group
		margin-bottom 0
		select
			font-style italic
			font-size 16px				
	nav			
		background-color $color-white
		border 1px solid #00D3F1
		border-top 4px solid #03d3ef
		border-top-left-radius 5px
		border-top-right-radius 5px
		border-bottom 4px solid #03d3ef
		border-bottom-left-radius 5px
		border-bottom-right-radius 5px	
		+breakpoint( mobile )
			display none
			border-top-left-radius 0
			border-top-right-radius 0			
		.nav.nav-stacked
			li
				&:first-child
				a
					padding-top 30px	
				&:last-child
				a
					padding-bottom 30px
				a

					background-color $color-white
					font-family $font-family-titles
					font-size 26px
					color $color-gray
					padding 15px 20px
					margin-left 15px
					text-transform uppercase
			li.active
				a
					background-image url( '../img/arrow-menu.png' )
					background-repeat no-repeat
					background-position left center
					background-color $color-white
					font-family $font-family-titles
					color #03d3ef
	.current-content
		border-bottom 2px solid #00d3f1
		display block	
		.current
			font-family 'Open Sans Condensed', sans-serif
			font-size 18px
			color #226889
			padding-left 20px
			text-transform uppercase
			margin 0 0 10px
			font-weight 700
		i
			color #00d3f1
			font-size 30px
			float right
			height 23px			
[data-page='cadastro-profissional']	
[data-component='dados-pessoais']
	.form-group
		margin-bottom 0	
		select
			font-style italic
			font-size 16px					
	.title
		margin-bottom 50px	
	.icon-file-profile
		display inline-block	
		margin-right 5px
		height 33px
	.image-crop-content
		margin 50px 0
		text-align center
		
		.crop-content
			width 164px
			margin 0 auto
			+breakpoint( mobile )
				margin 0 auto
			label
				width 100%
				text-align left
			.btn-crop
				margin-top 10px
				width 100%
				background-color #03d3ef
				font-family $font-family-base
				font-weight 700	
				border 1px solid #03d3ef		
			.user-image
				width 174px
				height 180px
				background-color #ebebeb
				background-image url( '../img/register/image-crop.png' )
				background-repeat no-repeat
				background-position center
				border 1px solid #cccccc
				border-radius 3px
				margin 0 auto
				img
					width 164px
					height 150px
	.button-edit-content
		margin-bottom 28px
	.field-search
		margin-top 35px
		padding-left 0
		a
			font-size 16px
			margin-bottom 6px
			color $color-blue
			font-family $font-family-base
			font-style italic
			margin-left 5px
			text-decoration underline
		.icon-search
			margin-bottom 0
	.title-2
		h2
			display block
			text-align left
			padding-left 0
			margin-bottom 50px
			margin-top 40px
			font-family 'Open Sans Condensed', sans-serif
			font-size 36px
			text-transform uppercase
			color #818282
			background-color #fff
			position relative
			z-index 1		
	.relativa
		.tip-image
			position absolute
			right -220px
			width 220px		
			text-align left
			+breakpoint( tablet )
				right 15px
				width 40%
		.tip-phone
			position absolute
			right -220px
			width 220px
			+breakpoint( tablet )
				right 15px
				width 45%
				z-index 1
				display none
		.tip-agency
			position absolute
			right -220px
			width 220px	
			text-align left
			+breakpoint( tablet )
				display none
				right 15px
				z-index 1

	.form-button
		padding 25px
		margin-top 50px			
[data-page='cadastro-profissional-especialidade']
[data-page='cadastro-profissional-dados-academicos']
[data-page='cadastro-profissional-experiencia-profissional']
[data-page='cadastro-profissional-disponibilidade-atendimento']	
[data-page='profissional-meus-dados-disponibilidade-atendimento']
[data-page='profissional-meus-dados-especialidades']
[data-page='profissional-meus-dados-experiencia-profissional']
[data-page='profissional-meus-dados-academicos']

	.form-group
		margin-bottom 0	
		select
			font-style italic
			font-size 16px
	.title
		margin-bottom 50px
		+breakpoint( mobile )
			text-align center
		.icon-specialty	
			display inline-block 
			margin-right 5px
	.button-specialty
		margin-bottom 30px
		padding 22px 12px
		cursor pointer
	h4
		font-family $font-family-base
		font-size 16px
		color #818282
		font-weight 700
	.icon-attendance
		display inline-block
	.icon-file-profile
		display inline-block
		margin-right 5px		
	.icon-crefito	
		display inline-block
		.icon-attendance
			display block		
	.crefito
		display inline-block	
		margin-left 10px		
	.specialty-choose
		background-color #fff
		border 1px solid #03d3ef
		color #03d3ef
		border-radius 20px
		margin-bottom 10px
		padding 5px 20px
		margin-right 10px
		outline none
	.specialty-choose.error
		background-color #f4c2b1 
		border-color #f14c00 
		color #fff
	.specialty-choose.active
		background-color #03d3ef
		color #fff
		outline none
		border-radius 20px
		&:focus
		&:active
			border-radius 20px
			outline none
	textarea
		border 1px solid #03d3ef
		&:focus
			border 1px solid #03d3ef
	.form-button
		padding 25px
		margin-top 50px	
		margin-bottom 50px		
	.title-new-password
		margin-top 50px
	.-blue
		margin-top 25px
		background-color #00d3f1
		padding 9px 25px
	.cep-content-juristic-register
		display none
	.cep-content-juristic-register.active
		display block
	.specialty-div
		+breakpoint( mobile )
			padding 15px
[data-page='cadastro-profissional-dados-academicos']
[data-page='cadastro-profissional-experiencia-profissional']	
[data-page='cadastro-profissional-disponibilidade-atendimento']
[data-page='profissional-meus-dados-disponibilidade-atendimento']	
[data-page='profissional-meus-dados-experiencia-profissional']
[data-page='profissional-meus-dados-academicos']	
	.title
		margin-bottom 50px
	.icon-academic-data
	.icon-file-profile
		display inline-block
		margin-right 5px
	.btn-dados-academicos
	.attachment
		margin-top 25px
		width 100%
		color $color-white
		background-color #03d3ef
		font-family 'Open Sans', sans-serif
		font-weight 700
		border 1px solid #03d3ef	
		padding 9px 20px
		&:hover
			color $color-white
		+breakpoint( mobile )
			margin-bottom 30px
			
	.title-2
		h2
			display block
			text-align left
			padding-left 0
			margin-bottom 50px
			margin-top 40px
			font-family 'Open Sans Condensed', sans-serif
			font-size 36px
			text-transform uppercase
			color #818282
			background-color #fff
				
	.icon-academic-data-white
		display inline-block
	.box-content-text
		.academic-education
			span
				display inline-block
				margin-left 10px
		div
			padding 0
		.academic-icons
			margin-top 40px
	.action-icons
		margin-bottom 30px
		.faixa
			margin-left 40px
			color #DDA0DD
		.exclude
			color #f14c00
			margin-left 40px
		.cancel
			color #f14c00
		.exclude
		.edit
		.cancel
		.faixa
			cursor pointer
			font-size 16px
			.fa
				margin-right 10px
	.boxAddress
		margin-bottom 30px
		.icon-location
			display block
			margin-top 33px			
	.fa-file-pdf-o
		margin-right 5px
		display inline-block	
	.relativa
		+breakpoint( mobile )
			display none
[data-page='cadastro-profissional-experiencia-profissional']
[data-page='profissional-meus-dados-experiencia-profissional']
	.icon-file-profile
	.icon-professional-experience
		display inline-block
		margin-right 5px
	.icon-professional-experience-white
		display inline-block
	.period
		width 100%
	.relativa
		.tip-image
			position absolute
			right -220px
			width 220px
			top 0
			text-align left	
[data-page='cadastro-profissional-contrato']
	.checkbox
		+breakpoint( mobile )
			font-size 12px
[data-page='cadastro-profissional-especialidade']
	.icon-register
		display inline-block
		margin-right 5px
	.checkbox
		+breakpoint( mobile )
			font-size 12px
	h3
		color #818282
		font-size 26px
		text-transform uppercase
		font-family $font-family-titles
		margin-bottom 65px
		margin-top 40px
	p
	h4
		color #818282
		font-size 16px
		font-family $font-family-base	
		text-align justify	
		+breakpoint( mobile )
			font-size 14px	
			text-align justify	
	h4
		text-transform uppercase
		margin 25px 0
	.form-button
		padding 25px
		margin-top 50px
[data-page='cadastro-profissional-disponibilidade-atendimento']
[data-page='profissional-meus-dados-disponibilidade-atendimento']
	.icon-location-availability
		display inline-block
		margin-right 5px
	.tip-image
		position absolute
		right -220px
		width 220px
		text-align left
		top 20px
		&:before
			top 38px
	.field-search
		margin-top 35px
		padding-left 0	
		.icon-search
			margin-bottom 0
		a
			font-size 16px
			margin-bottom 6px
			color #03a7e0
			font-family 'Open Sans', sans-serif
			font-style italic
			margin-left 5px
			text-decoration underline
	.icon-location-active
		display block
		margin-top 33px
	.period
		width 100%
	.academic-education
		padding-left 10px
	.icon-unmask
	.icon-mask
		display inline-block
	.time-control
		// border 1px solid #03d3ef
		border-top-right-radius 5px
		border-top-left-radius 5px
		.header-week.header-blue
			.box
				background-color #03d3ef
				padding 28px
				h4
					color #fff
					font-size 24px
					font-family $font-family-titles
					font-weight 700
					text-transform uppercase
					margin 0
				.icon-white-arrow-select
					margin-top 10px
					display inline-block
					background-image url( '../img/white-arrow-select.png' )
					background-repeat no-repeat
					background-size 30px 14px
					background-position right center
					width 30px
					height 14px
				
		.header-week
			h4
				font-size 14px
				font-weight 100
				color #03d3ef

			.box
				background-color #fff
				padding 28px
				h4
					color #818282
					font-size 24px
					font-family $font-family-titles
					font-weight 400
					text-transform uppercase
					margin 0
				.icon-arrow-select
					margin-top 10px
					display inline-block
					background-image url( '../img/arrow-select.png' )
					background-repeat no-repeat
					background-size 30px 14px
					background-position right center
					width 30px
					height 14px				
	.body-week
		display block
		h4
			margin 0
			font-size 16px
			font-weight 400
			margin-bottom 10px
			font-family $font-family-base
			color #818282
			display inline-block
		.body-week-left
			border-right 1px solid #cccccc
		.body-week-right
			h4
				margin-top 15px
				margin-left 4px
				font-weight 600
			.hour-free
				position relative
				padding 25px
				padding-bottom 45px
				border 2px solid #03d3ef
				border-radius 5px
				margin-bottom 20px	
				cursor pointer			
				span
					color #818282
					font-size 14px
				.fa-times
					color #f14c00
					position absolute
					right 10px
					top 5px
				.horarios
					bottom 7px
					position absolute
					margin-left 35px
		.-blue
			padding 10px
			margin-top 10px
	.icon
		display inline-block
		margin-top 10px
	.turn-hours
		font-size 16px	
	.box
		span
			margin-right 0	
		.icon-clock
			margin 5px
				
	.button-availability
	.button-availability-tuesday
	.button-availability-wednesday
	.button-availability-thursday
	.button-availability-friday
	.button-availability-saturday
	.button-availability-sunday
		margin-bottom 16px
		.turn
			display block
	.box-content-text
		.turn-specific-text
			display block
		select
			font-style italic
			display inline-block
			width 40%
			
			+breakpoint( mobile )
				width 34%
	.container-hour
		margin-bottom 8px
		display inline-block
		
		&.selected
			.specialty-choose
				background-color #03cde9
				h4
					color white				
	.serviceAddress 
		display none
	.EditAddress
		display none
		margin-top 20px
[data-page='cadastro-profissional-central-de-ajuda']
[data-page='cadastro-profissional-status-envio']	
	.title
		h2
			color $color-gray
			font-family 'Open Sans Condensed', sans-serif
			font-size 36px
			text-transform uppercase
			display table
			text-align center
			background-color #fff
			margin 0 auto
			padding 0 20px
			position relative
			z-index 1
			.icon-file-profile
				margin-right 5px
	i.fa-angle-down
		font-size 39px
		color #266988
		float right
		margin-top -7px	
	i.icon-asked-question
		margin-right 5px
	.home
		h3.sub-title
			color $color-gray
			font-size 18px
			font-family $font-family-base
			margin-top 40px
			margin-bottom 50px
		.form-contact
			h2
				margin 0
				padding 0
				margin 50px 0 40px 0
				text-align left
			form
				label
					color $color-gray
					font-size 16px
					font-family $font-family-base
				select
					color $color-gray
					font-size 16px
					font-family $font-family-base
				a.btn-primary
					background-color #03d3ef
					font-family 'Open Sans', sans-serif
					font-weight 700
					border 1px solid #03d3ef
					padding 15px 50px
[data-page='profissional-meus-dados-disponibilidade-atendimento']
[data-page='profissional-meus-dados-especialidades']
[data-page='profissional-meus-dados-experiencia-profissional']
[data-page='profissional-meus-dados-academicos']	
	.servicesContent
		display none
	h2
		color $color-gray
	h3
		color $color-gray
		text-transform uppercase
		font-size 30px
		font-weight 200
		font-family $font-family-titles
[data-page='profissional-meus-dados-academicos']
	h3
		margin-bottom 40px
	.tip-image
		position absolute
		right -220px
		width 220px
		top 0
		text-align left	
[data-page='cadastro-profissional-dados-academicos']	
	.tip-image
		position absolute
		right -220px
		width 220px
		top 0
		text-align left	
[data-page='cadastro-profissional-especialidade']
	.servicesContent
		display none
[data-page='cadastro-profissional-experiencia-profissional']
[data-page='profissional-meus-dados-experiencia-profissional']
	.experience-item
		margin-top 30px
		.form-component
			display none
[data-page='cadastro-profissional-dados-academicos']
[data-page='profissional-meus-dados-academicos']
	.attachment-pdf
		color #03d3ef
		position absolute
		bottom 21px
		right 17px
		cursor pointer
		+breakpoint( mobile )
			bottom 5px
	.EditAcademicContent
		display none
	.storedIntitution
	.storedFormation
	.storedDate
	.storedCertificate
		width 95%
	.storedCertificate
		a
			color $color-white
			text-decoration none
			&:hover
				text-decoration underline
	.qualification
		&:not(first-child)
			margin-bottom 30px	
[data-page='cadastro-profissional-disponibilidade-atendimento']
	
	.box-content-text
		width 100%
		.form-control
			margin-top 8px
		.horario
			.fa-times
				color #f14c00
				position absolute
				right 10px
				top 0
[data-page='profissional-meus-dados-pessoais']
	.title-new-password
		margin-top 50px
	.submit-nova-senha
		margin-top 26px
		padding 8px
		
