@import 'nib'
@import 'utils.styl'

[data-page='pre-cadastro-profissional']
	background-image url( '../img/login-profissional/bg-pre-cadastro-profissional.png' )
	background-repeat no-repeat
	background-position top center
	background-size cover
	.bg-login
		padding 100px 70px 50px 70px
		+breakpoint( mobile )
			padding 50px 5px
		h3
			font-family $font-family-base
			font-weight 700
			font-size 16px 
			color $color-gray
			text-align center
			margin-bottom 25px
		.form-login
			color  $color-gray
			background-color #fff
			padding 28px
			border 2px solid #638c96
			border-radius 5px
			border-bottom 4px solid #03d3ef
			form
				.to-access
					margin-top 50px
					border-top 2px solid #f1f1f1
					text-align center
					padding-top 20px
					a
						color $color-blue
						font-size 16px
						font-family $font-family-base
						font-weight 700
						text-decoration none
				.no-pad-left
					padding-left 0
					+breakpoint( mobile )
						padding-left 15px
				.no-pad-right
					padding-right 0
			.form-group
				margin 0
				.popUpPass
				.popUpCpf
					font-size 25px
				a
					.fa
						font-size 25px	
				.relativa
					position relative
					.popPass
					.popCpf
						position absolute
						top 0
						left 40%
						display none	
						&::before
							border-top 15px solid transparent
							border-left 15px solid transparent
							border-right 15px solid transparent
							border-bottom 15px solid #00d3f1
							left 50%
							right 0
							top -30px
						h4
							font-family $font-family-titles
							font-weight 700
							font-size 18px
						p
							font-size 14px				
			.half-input
				width 90%
				display inline-block
				+breakpoint( mobile )
					width 100%
				label
					margin-bottom 10px
					margin-top 20px
				input
					padding 14px 19px
					margin-bottom 0
					display inline-block
				small
					font-size 14px
					font-family $font-family-base
	.reveal-modal
		top 30%



						