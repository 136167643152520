@import 'nib'
@import 'utils.styl'

[data-page="index"]
	.call-to-action
		margin 65px 0 45px

		.content
			min-height 305px
			padding 0 25px
			text-align center
			border solid 2px #81E9F7
			border-radius 5px
			position relative

		.icon
			display block
			margin 0 auto 15px
			position relative
			top -15px

		.title
			font-family $font-family-titles
			font-size 24px
			color $color-gray
			text-transform uppercase
			display inline-block
			background #fff
			padding 0 12px
			margin 0 0 35px
			position relative
			top -15px

		.description
			font-family $font-family-base
			font-size 16px
			color $color-gray
			margin 0 0 15px
			
			&.-bottom
				margin-bottom 57px

		.btn
			width 100%
			position absolute
			bottom 0
			left 0
			color $color-blue
			font-size 16px
			font-weight 600
			border-radius 0
			padding 0 25px 17px
			text-transform uppercase
			text-align center
			font-family $font-family-base

			hr
				border-top solid 2px #53BFEB
				margin 0 0 17px 0
							
		.header-icon
			height 65px

	.form-button
		&.-top
			margin-top 27px
			
	+breakpoint( tablet )
		.title 
			&.-top
				margin-top 0
				
		.row
			&.-turns
				margin-bottom 30px
			
	.register
		display none
		margin-top 30px
		
		&.active
			display block
		
		.form-button
			width 100%
	
			


				
