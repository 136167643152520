$media_queries = {
  mobile  : 'only screen and (max-width: 480px)',
  large-mobile: 'only screen and (max-width: 768px)',
  tablet  : 'only screen and (max-width: 992px)',
  tablet-desktop: 'only screen and (min-width: 992px) and (max-width: 1200px)',
  desktop : 'only screen and (max-width: 1024px)',
  laptop  : 'only screen and (min-width: 1025px) and (max-width: 1366px)',
  full-hd : 'only screen and (min-width: 1367px)'
}

breakpoint( $breakpoints )
	$conditions = ()
	for $breakpoint in $breakpoints
		push( $conditions, $media_queries[$breakpoint] )
	$conditions = join( ', ', $conditions )
	@media $conditions
		{block}
		
clearfix()
	&::after
		content ' '
		clear both
		display block


/*------------------------------------
	#FOTNS
------------------------------------*/
/*
* #Family
* font-family: 'Open Sans', sans-serif;
* font-family: 'Open Sans Condensed', sans-serif;
*/

$font-family-base = 'Open Sans', sans-serif
$font-family-titles = 'Open Sans Condensed', sans-serif
$font-family-roboto = 'Roboto', sans-serif;

/*------------------------------------*\
	#COLORS
\*------------------------------------*/

$color-white = #fff

$color-gray = #818282

$color-blue = #03a7e0
$color-blue--light = #00D3F1

$color-yellow = #FBB731

$color-green = #A1B953

$color-orange = #F14B00 

