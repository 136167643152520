@import 'nib'
@import '../../../assets/styl/utils.styl'

[data-component='block-notices']
	margin-bottom 30px
	
	.content
		border solid 1px $color-blue--light
		border-top solid 10px $color-blue--light
		border-bottom solid 5px
		border-radius 7px
		border-color $color-blue--light
		margin-bottom 30px

	.img
		width 100%
		height 256px
		background-size cover
		background-position center center
		display block 
		
	.title
		font-family $font-family-titles
		font-size 24px
		margin 0 0 20px
		color $color-gray
		text-transform uppercase

	.description
		font-size 16px
		line-height 130%
		color $color-gray
		font-family $font-family-base
		margin 20px 0

	.attachment
		font-style italic
		font-size 16px
		color $color-blue--light
		text-decoration underline

		.icon
			display inline-block
			margin-right 10px
			position relative
			top 2px

	.box-infos
		padding 25px 30px



