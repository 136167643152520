@import 'nib'
@import 'utils.styl'

main
	&.register
		min-height 655px
		
.register
	
	.register-container
		padding 50px 0 0 70px
		
		+breakpoint( tablet )
			padding 50px 15px
			
	.register-nav-list
		margin-top 0
		
		li 
			width 25%
			text-align left
			padding-left 18px
			
			+breakpoint( tablet )
				display none
				
				&.active
					+breakpoint( tablet )
						display block
						width 100% 
			
		.active
			span
				color $color-blue--light
				
	.register-content
		display none
	
		&.active
			display block
					
	.perfil-content
		display none
		
		&.active
			display block
		.cpf-alert
		.cnpj-alert
			display none
			position absolute
			z-index 1
			top 0
			right -192%
	.form-button
		&.-green
			width 200px
			float right
			
		&.-transparent
			width 200px
			
		&.new-cep-charges
			width 100%
			
	.text-fields-required
		color $color-gray
		margin-top 21px
		
	.title-patient
	.title-perfil
		+breakpoint( tablet )
			font-size 19px
		
	.row
		&.-text-fields-required
			padding-top 16px
			
		&.-address
			padding-top 40px
			
		&.-buttons
			padding-top 30px
			
			.-transparent
				margin-bottom 30px
			
		&.-turns 
			> div 
				height 400px
				
		&.-selections
			padding-top 21px
			position relative
			
			+breakpoint( tablet )
				padding-top 0
			
			.icon
				display block
				margin 48px auto 0
				
				+breakpoint( tablet )
					margin-top 20px
				
			.title-selection
				font-weight bold
				font-size 24px
				text-align center
				color $color-gray
				margin 8px 0 27px
				
			select
				display inline-block
							
			.item-select
				width 270px
				display inline-block
				margin-right 25px
				vertical-align top
				
				+breakpoint( tablet )
					display block
					width 100%
					margin-bottom 30px
				
				&:last-child
					margin 0
				
			.item-select-division
				width 100%
				display inline-block
				
				+breakpoint( tablet )
					display block
					width 270px
					margin 0 auto
				
			.tooltip-form
				position absolute
				right 15px
				top 64px
				width 252.5px	
			
	.search-cep
		color #03a7e0
		text-decoration underline
		font-style italic
		margin-top 35px
		font-size 16px
		display block
		
		+breakpoint( tablet )
			margin 0 0 15px
		
		span
			display inline-block
			vertical-align bottom
			margin-right 5px
			
	.tooltip-perfil
		margin-top -8px
		
	.pass-1-form-1
		margin-bottom 45px
			
	.checkbox
		margin-top 35px
		
		+breakpoint( tablet )
			margin 0 0 15px
		
	.button-edit
		display inline-block
		color #03a7e0
		margin 0 0 10px 10px
		vertical-align bottom
		
	.sub-title
		margin 5px 0 17px
		min-height 17px
		
	.title-value-service
		font-size 24px
		font-family $font-family-titles
		margin 48px 0 5px
		
	.value-service 
		font-size 30px
		font-weight bold
		color #03d3ef
		margin-bottom 40px
		
	.box-textarea
		margin-bottom 50px
		
	.button-turn
		float left
		
		+breakpoint( tablet )
			float none
			display block
			width 100%
		
		&:last-child
			float right
			
		span:first-child
			margin-right 0
			
		&.-little
			width 212px
			margin-bottom 16px
			
			+breakpoint( tablet )
				width 100%
				
		.turn 
			font-size 24px
			display inline-block
			line-height 24px
			
		.turn-hours
			font-size 16px
			display block
			
		.turn-specific-text
			font-size 24px
			color $color-gray
			text-transform uppercase
			font-family $font-family-titles
			margin-bottom 6px
			
			+breakpoint( tablet )
				text-align center
				
			.icon-unmark
				margin-right 6px
			
		.box-content-text
		.icon-unmark
			vertical-align middle
			
		select
			width 125px
			display inline-block
			
			+breakpoint( tablet )
				margin-bottom 0
			
		.icon
			display inline-block
			margin-right 15px
			
			+breakpoint( tablet )
				display none
			
	.turn-little-tip
		margin-top 39px
		
	.turn-specific-tip
		margin-top 92px
				
	.button-pay
		padding 25px 17px
		margin-bottom 20px
		
		+breakpoint( tablet )
			padding 25px 10px
		
		.box-content-text
			width 96%
			
			+breakpoint( tablet )
				width 100%
			
		i 
			font-size 30px
			vertical-align middle
			margin 0 17px 0 7px
			
			+breakpoint( tablet )
				margin 0 9px 0 3px
			
		&.active
			i 
				color #fff
				
			.pay-pipe
				background #fff
				
	.pay-title
		font-size 18px
		min-width 176px
		
		+breakpoint( tablet )
			min-width auto
		
	.pay-sub-title
		font-size 16px
		display block
		
	.pay-text
		font-size 14px
		width 50%
		
		+breakpoint( tablet )
			width 100%
			text-align center
			margin-top 15px
			font-size 16px
		
	.pay-pipe
		width 2px 
		height 50px
		background $color-gray
		margin-right 6px
		
		+breakpoint( tablet )
			display none
		
	.pay-content
		padding-top 18px
		
	#register-pass-4
		.-green
			width 280px
			
	.text-type-pay
		margin-bottom 47px
		
		+breakpoint( tablet )
			font-size 16px
			text-align center
		
	.shelf-life-input
		width 60px
		display inline-block
		margin-right 10px
	
	.security-code-label
	.shelf-life-label
		display block
		
	.icon-code-security
		display inline-block
		vertical-align middle
		
	.security-code-input
		width 80px
		margin-right 10px
		display inline-block
		
	.pay-content
		display none
		
		&.active
			display block

	.text
		font-size 18px
		margin 50px 0
		color $color-gray
		text-align center
		
	.icon-center
		display block
		margin 0 auto 20px
		
	.button-center
		&.-green
			float none
			margin 0 auto 50px

	&.registration-completion
		
		.registration-completion-banner
			background url( '../img/register/registration-completion.jpg' ) no-repeat center center
			background-size cover
			height 524px
			position relative
			
		.title
			max-width 1033px
			margin 0 auto
			
	.box 
		&.-title
			min-height 140px
			margin-top 107px
			margin-bottom 50px
			text-align center
			color $color-gray
			cursor auto
			
			.-items 
				text-transform uppercase
				font-family $font-family-titles
				font-size 22px
				
				+breakpoint( tablet )
					text-align left
					padding-left 10px
			
			.title-header
				margin-top -107px
				margin-bottom 40px
				
				h2
					margin 0 auto
					background #fff
					display inline-block
					padding 0 20px
					font-family $font-family-titles
					text-transform uppercase

			.icon
				display inline-block
				vertical-align middle
				margin-right 5px
			
	.form-cancel-submit
	.form-cancel-submit-jur
	.form-button.new-cep-charges
		display inline-block
		margin-bottom 15px
		color #818282
		border 0
		text-align left
		font-weight 500
		font-size 16px
		text-transform none
		text-decoration underline !important
		padding 0
		background transparent
		
		&:hover
			background transparent
			
		
	.cep-content-juristic-register
	.cep-content-juristic-charges
	.cep-content-physical-register
	.cep-content-physical-charges
	.cep-content-patient
		display	 none
		
		&.active
			display block 
		.form-cancel-submit
		.form-cancel-submit-jur
			margin-bottom 20px
			
	.banner-title
		position absolute
		top -90px
		left 0
		right 0
		margin auto
		text-align center
		width 88.5%
		
		+breakpoint( mobile )
			position relative
			top 0
			width 100%
		
		h2
			font-family $font-family-titles
			font-size 30px
			text-transform uppercase
			color #fff
			font-weight 700
			position relative
			
			+breakpoint( mobile )
				color $color-gray
			
			+breakpoint( tablet )
				&::before
				&::after
					display none
			
			&::before
			&::after
				content " "
				position absolute
				top 0
				bottom 0
				margin auto
				border-top 2px solid #fff
				height 2px
				width 209px
				
			&::before
				left 0
				
			&::after
				right 0
				
			

	.input-search-pathology
		position relative
		
	.pathology-type:focus + .list-result
		border-color #66afe9
		outline 0
		box-shadow 0px 6px 10px -2px rgba(102,175,233,0.6)
				
	.form-control
		&:focus
			border-color #66afe9 !important
			
	.row.-selections
		.unknow-pathology
			display inline-block
			margin-bottom 15px
			vertical-align bottom
			
			.icon 
			p
				display inline-block
				margin 0
				vertical-align middle
				
			p 
				margin-right 10px
				
	.perfil-border
		border 3px solid #818282
		margin-bottom 50px
		