@import 'nib'
@import '../../assets/styl/utils.styl'

html
body
	overflow-x hidden
	position relative
	
main 
	padding-bottom 50px
	min-height 808px

body
	font-family $font-family-base
	
	.slick-prev
	.slick-next
		top 45%
		height 70px 
		width 25px
		z-index 100
		
	.slick-prev
		left -55px
		
		+breakpoint( tablet )
			left 20px
			
	.slick-next
		right -55px
		
		+breakpoint( tablet )
			right 20px
			
	.slick-track
		min-height 200px

	.reveal-modal
		width 570px
		margin-left -285px
		padding 0
		background transparent
		margin-bottom 200px
		
		+breakpoint( tablet ) 
			width 480px
			margin-left -240px
			
		+breakpoint( mobile ) 
			width 90%
			margin-left -45%
			
		.revel-modal-header
			background #03d3ef
			display block
			position relative
			min-height 50px
			border-top-right-radius 5px
			border-top-left-radius 5px
			
		.revel-modal-body
			padding 40px 27px 27px
			background #fff
			border-bottom-right-radius 4px
			border-bottom-left-radius 4px
			
			+breakpoint( mobile )
				padding 20px 10px 17px
			
		.close-reveal-modal
			color #fff
			font-size 35px
			font-weight 100
			top 16px
			right 14px
			
			&:hover
				text-decoration none
				
		p 
			font-family $font-family-base
			font-size 16px
			color $color-gray
			margin 15px 0 15px
			line-height 20px
			
		.box 
			padding 0
			background transparent
			border-top 0
			cursor auto
			

		.img-full
			width 100%
			height 250px
			background-size cover
			background-repeat no-repeat
			
		.link
			color #53bfeb
			display block
			margin 0 auto
			text-align center
			font-style italic
			text-decoration underline
			margin 21px 0 0
			font-size 16px
			
			i
				display inline-block
				vertical-align middle
				margin-right 7px
				
		.button-modal
			color #fff
			text-transform uppercase
			text-align center
			padding 8px
			display block
			margin 0 auto
			background #53bfeb
			border-radius 5px
			font-size 16px
			font-weight bold
			display block
			max-width 220px
			margin-top 9px
			transition all 1s
			-webkit-transition all 1s
	        
			&:hover
				text-decoration none
				background #97e0fd
			
			+breakpoint( mobile )
				max-width 100%
				margin 15px 0 0 0
				
		.highlight
			margin-top 15px
			display block
				
	.reveal-modal-bg
		background rgba(0,0,0,0.2)
		
	.title
	
		&.-top
			margin 40px 0 40px

		hr
			border-top solid 2px $color-blue--light
			width 100%
			margin 0
			position relative
			top -20px

		h2
			font-family $font-family-titles
			font-size 36px
			text-transform uppercase
			color $color-gray
			display table
			text-align center
			background-color $color-white
			margin 0 auto
			padding 0 20px
			position relative
			z-index 1

		.icon
			display inline-block
			margin-right 5px
	.title.no-warnings
		margin-top 250px
	.hr-spacer
		hr
			border-top solid 2px $color-blue--light
			width 100%
			margin-top 10px
			position relative


label
	font-size 16px
	color $color-gray
	font-weight 400
	margin-bottom 5px

input
	width 100%
	height 40px
	border-radius 5px
	border solid 1px #ccc
	padding 0 10px
	color $color-gray
	margin-bottom 15px
	
	&::-webkit-input-placeholder
		color $color-gray
		font-style italic
		font-size 16px

	&::-moz-placeholder
		color $color-gray
		font-style italic
		font-size 16px

	&:-ms-input-placeholder
		color $color-gray
		font-style italic
		font-size 16px

	&:-moz-placeholder
		color $color-gray
		font-style italic
		font-size 16px
		
	&[type='number']::-webkit-outer-spin-button
	&[type='number']::-webkit-inner-spin-button
		-webkit-appearance none
		
.no-padding
	padding 0 !important
	
header
	.nav-list
		li 
			&:hover
				&::after
					opacity 1
					
		a				
			&:hover
				color #266988
				text-decoration none

.nav-list
	clearfix()
	
	margin 25px 0 0 0
	padding 0
	
	li
		width 195px
		float left
		list-style none
		position relative
		text-align center
		border-bottom solid 1px #ccc
		font-size 18px
		color #ccc
		text-transform uppercase
		font-family $font-family-titles
		font-weight 600
		display block
		margin-bottom 5px
		transition .3s ease-in-out
		+breakpoint( tablet )
			float none
			margin 0 auto
			margin-bottom 20px

		&::after
			opacity 0
			content ' '
			height 4px
			width 100%
			position absolute
			bottom -5px
			left 0
			background-color #00D3F1
			transition .3s ease-in-out
			
		&.active
			color #266988

			a
				color #266988
				&:hover
				&:active
				&:visited
					color #266988
					
			&::after
				opacity 1
				
		a
			color #ccc
			font-weight 600
			

					
			&:active
			&:visited
				color #ccc
				
.form-button
	display block
	width 100%
	padding 8px
	text-transform uppercase
	font-weight bold
	font-size 16px
	border-width 1px 
	border-style solid
	border-radius 5px
	text-align center
	transition all 1s
	-webkit-transition all 1s
	text-decoration none !important
	position relative
	cursor pointer
	&[disabled]
		background #e5e5e5 !important
		border-color #e5e5e5 !important
		color #fff !important
	
	&.-full
		width 100% !important
	
	&.-green
		color $color-white
		background #a2ba54
		border-color #a2ba54
		
		&:hover
			background #b6d15e
		
	&.-transparent
		color $color-blue
		background transparent
		border-color $color-blue
		
		&:hover
			background $color-blue
			color #fff
			
	&.-blue
		color #fff
		background #53bfeb
		border-color #53bfeb
		
		&:hover
			background #91defd
	&.-blue.-top
		margin-top 26px
			
	&.-top-border
		border 0
		color #03a7e0
		background transparent
		border-top 2px solid #f1f1f1
		


	+breakpoint( tablet )
		display block
		margin 0 auto
		float none !important

.form-control
	height 40px
	margin-bottom 15px
	
	&[disabled]
		background-color #eee !important
	
	&.error
		border solid 1px #f14c00 !important
		background-color #f4c2b1 !important


.sub-title
	margin 0
	color $color-gray
	font-size 16px
	font-family $font-family-base
	
.title-underline
	font-family $font-family-titles
	border-bottom 2px solid #03d3ef
	font-size 24px
	text-transform uppercase
	color $color-gray
	display inline-block
	margin 0

.checkbox
	color #03d3ef
	font-size 16px
	position relative
	font-style italic
	cursor pointer
	text-indent 22px
	
	&[disabled]
		&::before
			background-color #eee !important
			
		input
			display none
		
	&::before
		content ' '
		position absolute
		width 16px
		height 16px
		border 1px solid #03a7e0
		left 0
		top 0
		bottom 0
		margin auto
		
	.icon-verify
		display none
		position absolute
		left -22px
		top 3px
		z-index -1
	input
		&:checked + .icon-verify
			display block
	
.box
	display inline-block
	width 100%
	position relative
	border 2px solid transparent
	border-radius 5px
	background #fff
	background-clip padding-box
	padding 12px
	text-decoration none
	vertical-align middle
	cursor pointer
	
	+breakpoint( tablet )
		padding 12px 6px

	&::before
		position absolute
		top -2px 
		bottom -2px
		left -2px 
		right -2px
		background linear-gradient( bottom, #03d3ef, #a9f0fa )
		content ' '
		z-index -1
		border-radius 5px
		
	&:hover
		text-decoration none
	
	span
		display inline-block
		vertical-align middle
		
		&:first-child
			margin-right 14px
			
			+breakpoint( tablet )
				margin-right 2px
				
	&.error
		border solid 1px #f14c00 !important
		background-color #f4c2b1 !important
		color #fff !important
			
	&.button-location
		margin-bottom 30px
		padding 22px 12px
		
		&:nth-child( even )
			margin-right 0
			
		.box-content-text
			padding-left 10px
			
			+breakpoint( mobile )
				padding-top 10px
				display block
			
	&.button-perfil
		width 300px
		margin-right 30px
		
		+breakpoint( tablet )
			width 100%
			margin 0 0 30px
		
	&.perfil-patient
	&.perfil-register-patient
		width 340px
		
		+breakpoint( tablet )
			width 100%
			margin 0 0 30px
			
	&.error
	&.active
		background #03d3ef
		border-color #03d3ef
		
		.box-content-text
			color #fff
			
			.turn-specific-text
				color #ff
				
		select
			border 1px solid #fff !important
			color #fff !important
			background-image url('../img/white-arrow-select.png') !important
			background-repeat no-repeat !important
			background-position right !important
			
			option 
				background-color #03d3ef
				color #fff
					
	&.-info
		height 150px
		padding 25px
		
		+breakpoint( tablet )
			padding 12px
			margin-bottom 30px
			
			.box-content-text
				width 75%
				
		
	&.-central
		display table
		
		.box-content
			display table-cell
			vertical-align middle

	.box-content-text
		color $color-gray
		font-size 20px
		font-family $font-family-base
		margin-left 2px
		display inline-block
		
		+breakpoint( tablet )
			font-size 18px
		
	&.box-textarea
		height 125px
		
		textarea
			margin 0
			padding 10px 10px
			border 0
			outline 0 !important
			width 100%
			height 100%
			color $color-gray
			font-style italic
		
	&.box-button
		transition all 1s
		-webkit-transition all 1s
	    
		&:hover
			background-color #03d3ef
			
			.form-button
				color #fff
			
		.form-button
			border 0
			color $color-gray
			font-family $font-family-base
			font-weight 500
			text-transform none
			font-size 20px
			
			i
				display inline-block
				vertical-align middle
				margin-right 10px
				
*
	&:hover
		> .tooltip-form
			&.-out
				opacity 1
				
.tooltip-form
	width 100%
	padding 18px
	background $color-blue--light
	color $color-white
	font-size 14px
	border-radius 5px
	position relative
	margin-bottom 0
	transition all 1s
	-webkit-transition all 1s
	        
	&::before
		content ' '
		position absolute
		width 15px
		height 15px
		border-bottom 15px solid transparent
		border-right 15px solid $color-blue--light
		border-top 15px solid transparent
		left -10px
		top 30px
		
	&.-top
		&::before
			left 0
			right 0
			margin auto 
			top -25px
			border-bottom 15px solid $color-blue--light 
			border-right 15px solid transparent
			border-left 15px solid transparent	
		
	&.-out
		opacity 0
		top 60px
		position absolute
		z-index 100
		width 162px
		margin auto
		left 0
		right 0
		font-weight 500
		text-transform none
		text-align left

	span
		font-weight bold
	
body	
.datepicker
	.ui-datepicker-header
		padding 15px 0 !important
		border-bottom-left-radius 0
		border-bottom-right-radius 0
		
	.ui-datepicker-month
	.ui-datepicker-header
		background #03a7e0
		font-family $font-family-titles
		font-size 24px
		color #fff
		text-transform uppercase
		border 0
		
	.ui-datepicker-year
		display none
		
	.ui-datepicker-next
	.ui-datepicker-prev
		background #03a7e0
		color #fff
		font-size 20px
		top 0 
		bottom 0
		margin auto
		width 15px !important			
		height 26px !important	
			
		&:hover
			background #03a7e0
			color #fff
			border 0
			
	.ui-datepicker-next
		right 20px !important
		
	.ui-datepicker-prev
		left 20px !important

	.ui-datepicker-inline
		width 100%
		border 0 !important
		padding 0
		
	.ui-datepicker-calendar
		border 0
		max-width 270px
		margin 0 auto
		margin-bottom 20px
		
	.ui-state-default
		border 0 !important
		background transparent !important
		text-align center !important
		font-size 17px
		padding-top 7px
	.ui-datepicker
		th
			font-family $font-family-base
			font-size 12px
			padding-top 28px
			
		td
			color $color-gray
		
	a.ui-state-active
		color #fff !important
		background-color #03d3ef !important
		border-radius 6px
		padding-top 7px
	
.banner
	width 100%
	
select
	-webkit-appearance none
	-moz-appearance none
	text-indent 1px
	text-overflow ''
	position relative
	background url('../img/arrow-select.png') no-repeat right !important;
	background-color transparent !important
	border 1px solid #ccc !important
	border-radius 4px !important
	
	&.error
		option
			border solid 1px #f14c00 !important
			background-color #f4c2b1 !important
			
.link-style
	font-style italic
	color #03d3ef
	text-decoration underline
	
	&:active
	&:visited
	&:hover
		color #03d3ef
		
		
.link-style-2
	font-style italic
	color #03a7e0
	text-decoration underline
.link-style-5
	border 1px solid $color-gray
	color $color-gray
	padding 14px 45px 10px
	font-family $font-family-base
	border-radius 5px
	&:active
	&:visited	
	&:hover
		text-decoration none
		color $color-gray !important
	span
	i
		margin-right 5px
		display inline-block
		vertical-align middle
	
	&:active
	&:visited
	&:hover
		color #03a7e0
		
.link-style-3
	color #03a7e0 !important
	
	&:active
	&:visited
	&:hover
		color #03d3ef !important
		
.link-style-4
	color #454545
	text-decoration underline
	
	&:active
	&:visited
	&:hover
		color #454545				
		
.title-section
	color $color-gray
	font-size 24px 
	font-family $font-family-titles
	text-transform uppercase
	margin 0 0 25px
	
	+breakpoint( tablet )
		text-align center
		
.img-mask
	width 50px
	height 50px
	overflow hidden
	border  2px solid #cccccc
	border-radius  50%
	display  inline-block
	margin  0 auto 9px 
	background-size: cover;
	background-repeat no-repeat
	vertical-align middle
	margin 0 10px 0 0
	background-position center center

