@import 'nib'
@import 'utils.styl'

[data-page="financial"]
	.reveal-modal
		width 970px
		margin-left -435px
		
		+breakpoint( tablet )
			width 73%
			margin-left -36.5%
			
		+breakpoint( mobile )
			width 100%
			margin-left -50%
			
		img 
			width 50px 
			height 50px
			
		.table-item 
			> div 
				padding-top 15px !important
				padding-bottom 15px !important
				
				+breakpoint( mobile )
					font-size 14px
					font-weight 300
					text-align left
					
		.img-mask
			+breakpoint( mobile )
				display none
				
		b 
			+breakpoint( mobile )
				font-size 14px
				font-weight 400
				
		.title
			h2 
				font-size 25px
				
			hr 
				top -14px

											