@import 'nib'
@import '../../assets/styl/utils.styl'

[data-partial='header-profissional']

header
	padding-bottom 15px
	.-top
		margin-top 60px !important
	.row
		> div
			clearfix()
			
	.status 
		display none
		
	nav
		border-top solid 3px #F6F6F6

	.container
		padding 0
		
		+breakpoint( tablet )
			padding 0 15px

	.logo
		float left
		
	.nav-list
		li
			width 160px
			&:nth-child( 5 )
				width 195px
		a
			&:hover,
			&:focus
				color #266988

				&::after
					opacity 1
	.nav-list.menu-professional-data
		li
			width 19%
			&:nth-child( 3 )
				width 24%
	.user-list
		float right
		list-style none
		margin 22px 0
		padding 0
		
		.img-mask
			width 33px
			height 33px
			margin 0 
		&:nth-child( 4 )
			position relative

		li
			float left
			margin-right 15px				
		li.notifications-parent
			position relative
	.user-name
	
		font-size 16px
		color #818282
		margin 8px 0
		font-weight 100
	.professional-data
		margin 8px 0
		text-decoration underline
		a
			font-size 16px
			color #454545
			font-weight 200
	.icon-notifications
		display block
		margin 9px 0
	.notifications 
		position relative 
		display block
		img.avatar
			border-radius 50%
			border 2px solid #818282
			display inline-block
			margin-top -3px
			width 36px
			height 36px
	.notifications-body.show-notifications
		opacity 1
		transform scale(1)
	.notifications-body
		cursor pointer
		border 1px solid  #00d3f1
		position absolute
		background-color #fff
		z-index 9999
		border-radius 5px
		left -105px
		top 50px
		opacity 0
		transform scale( 0 )
		width 260px
		transition all .4s ease-in-out
		+breakpoint( desktop )
			left auto
			right -15px

		.line-top
			border-top 15px solid transparent
			border-left 15px solid transparent
			border-right 15px solid transparent
			border-bottom 15px solid #00d3f1
			right 117px
			top -29px	
			position absolute
			+breakpoint( desktop )
				right 15px

		h5
			background-color #00d3f1
			margin 0
			padding 12px 80px
			color $color-white
			font-family $font-family-base
			font-size 14px
			text-transform uppercase				
		.notifications-list
			list-style none
			margin 0
			padding 0			
			li 
				padding 12px
				padding-bottom 0
				margin 0
				display inline-block
				float none
				i.icon-alert
					display inline-block
					margin-top 10px
					vertical-align middle
				.notification-inner-content			
					width 80%
					display inline-block
					padding-left 10px
					vertical-align middle
					p
						margin 0
					.btn-notification
						background-color transparent
						color #03d3ef
						border none
						font-size 14px
						text-decoration underline
						padding 0
				.line-bottom
					background: linear-gradient( to right, #fff, #ccc, #fff ) 	
					height 1px	
					margin-top 18px					
		.number 
			position absolute
			top -2px
			right -5px
			background #00d3f1
			border 1px solid #818282
			padding 0px 4px
			font-size 9px
			font-weight bold
			color $color-gray

	.btn-login,
	.btn-logout
		font-size 16px
		color #818282
		font-weight 100
		float right
		margin 32px 0 0 10px
		text-decoration underline
		font-family $font-family-base
		
	.btn-logout
		+breakpoint( tablet )
			display none

	&.logged
		.status-logged
			display block
			
		.btn-login
			display none
			
		& + main
			margin-top -1px

	&.no-logged
		.status-no-logged
			display block
			
		nav
			ul
				margin 29px 22px 25px 0
				
			li 
				width auto
				padding 0
				margin 0 35px 0 0
				border 0
				color #454545
				text-transform none
				
				.form-button
					padding 6px 38px
					font-family $font-family-roboto
					font-size 14px
					font-weight 400
					text-transform none
					border #cccccc				
				
				&:after
					display none

				a 
					font-family $font-family-roboto
					color #454545
					font-size 16px
					font-weight 300
					
					&:active
					&:visited
						color #454545
						
	&.header-register
		border-bottom 3px solid #f6f6f6
		.login
			float right
			font-size 16px
			margin-top 28px
			
	.menu-mobile
		padding 25px 0
		
		i
			color #00d3f1
			font-size 30px
			float right
			height 23px
			
		.current-content
			border-bottom 2px solid #00d3f1
			display block
			
		.current
			font-family $font-family-titles
			font-size 18px
			color #226889
			padding-left 20px
			text-transform uppercase
			margin 0 0 10px
			font-weight 700

			
		.list
			display none
			background #00d3f1
			margin -1px 0 0
			padding 30px 20px 0
			list-style none
			width 100%

			li 
				color #fff
				font-family $font-family-titles
				font-size 18px
				text-transform uppercase
				padding-bottom 30px
				margin 0
[data-partial='header-profissional']
	border-bottom none		
[data-partial='header-login-profissional']
	.login-content
		text-align right
		.name-user
			display inline-block	
			margin 45px 30px 0 0			
