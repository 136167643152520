@import 'nib'
@import 'utils.styl'

.home
	padding-bottom 0
	
	form
		+breakpoint( tablet )
			margin-top 70px	
	
	figcaption
	a
	h2
	h3
	h4
	label
	p
	input
	select
		font-family $font-family-roboto
		color #fff
		
	i
		display inline-block
		vertical-align middle
		
	input 
		font-size 18px
		font-weight 400
		font-style italic
		color $color-gray
		
	.block-highlight
		border 1px solid #e5e5e5
		border-top 4px solid #03d3ef
		border-top-left-radius 5px
		border-top-right-radius 5px


	.slick-initialized
		.slick-slide
			outline 0 !important
		
	/*SECTION HOME CARE PASSO 1*/
	.home-care
		text-align center
		height 100vh
		margin-left -15px
		margin-right -15px
		position relative
		-webkit-transition all 2s ease-in
		-moz-transition all 2s ease-in
		-o-transition all 2s ease-in
		-ms-transition all 2s ease-in
		transition all 2s ease-in
		overflow hidden
		+breakpoint( tablet )
			height auto
		.block-return
			padding 20px 48px 1px 48px
			margin-top 20px	
			margin-bottom -20px
			.divider
				height: 2px
				background-color #ccc
				width 80%
				margin 0 auto
				margin-bottom 20px
				margin-top -20px
			.blue-span
				display block
				color #03d3ef
				font-weight 700
				margin-top 10px
			.blue-span-value
				display block
				color #A2BA54
				font-weight 800
				font-size 18px
				margin-top 10px
		.form-button
			font-size 16px
			font-weight 500
			text-transform none
			padding 12px
			
			&.-next
				display inline-block
				width 300px
				margin 15px 0 21px
				
				+breakpoint( tablet )
					padding 11px 40px
					width 100% 
					
			&.-white
				width auto
				display inline-block
				font-size 14px
				color #454545
				padding 6px 31px
				border 1px solid #cccccc
				
		hr
			width 107px
			display block
			margin 0 auto 42px
			border-top 2px solid #fff
			


	.home-care-title
		font-size 46px
		font-weight 900
		margin 0 0 4px
		
	.home-care-subtitle
		font-size 26px
		font-weight 400
		margin 0 0 46px
		
	.place-care-text
		font-size 20px
		font-weight 300
		margin 0 0 16px
		
	.input-place-care
		position relative
		
		input 
			height 63px
			padding-left 25px		
			padding-right 145px
			
	.search-location
		position absolute
		right 15px
		top 0
		bottom 0
		margin auto
		height 34px
		font-size 11px
		font-weight 400
		font-style italic
		color $color-gray
		text-decoration underline

		

	.step-1
		background url('../img/home/home-care-banner.jpg') no-repeat
		background-size cover
		background-position center
		padding 260px 0
		height 100%
		+breakpoint( laptop )
			padding-top 177px
		
		+breakpoint( tablet )
			padding-top 260px

	.steps-content
		padding-top 80px
		overflow hidden
		
		+breakpoint( laptop )
			padding-top 30px		
		
		+breakpoint( tablet )
			padding 60px 0
		
		.container
			min-width 1170px
			
			+breakpoint( tablet )
				min-width 0
		
		.circle
			width 55px
			height 55px
			background #fff
			color #a2ba54
			font-size 22px
			font-weight 900
			border-radius 50%
			padding 9px
			border 1px solid #e3e4e4
			z-index 10
			
			&.-off
				background #e5e5e5
				color #fff
				
			&.-off
			&.-on
				margin-top 3px
				margin-bottom 6px
				
		.block-highlight
			padding 31px 10px
			height 180px
			cursor pointer
			position relative
			width 165px
			margin-bottom 17px
			
			+breakpoint( tablet )
				margin 0 auto
			
			&.active
				border-top 4px solid #a2ba54
				
				i
					display block
			
			figure
				display block
				width 70px
				height 70px
				margin 0 auto 13px
				
				img
					display block
					margin 0 auto
					
			figcaption
				font-size 18px
				font-weight 700
				color #454545
				
			i 
				position absolute
				bottom -18px
				left 0
				right 0
				margin auto
				display none

		.unknow
			font-size 14px
			font-weight 400
			color $color-gray
			margin 5px 0 0
			
			a 
				+breakpoint( tablet )
					display block 		
	.steps
		display none
		&.step-2
			display block

	/*SECTION HOME CARE PASSO 2*/
	.step-2-title
		font-size 28px
		font-weight 900
		color #454545
		margin 0 0 32px
		
	.circle-description
		font-size 16px
		font-weight 400
		color #454545
		margin 11px 0 0
		
		+breakpoint( tablet )
			display none
		
		span
			display block
			color #03d3ef
			font-weight 700
			margin-top 10px
			
	.numbers-content
		position relative
		margin-bottom 39px
		
		i
			+breakpoint( tablet )
				transform scale( .7 )
				
				&.-on
					transform scale( 1 )
		
		> div 
			z-index 10
		hr
			width 50%
			position absolute
			left 0
			right 0
			margin auto
			top 29px
			border 1px solid #e3e4e4
			z-index 5
			
			+breakpoint( tablet )
				width 65%
			
	.specialties-title
		font-size 26px
		font-weight 300
		color #454545
		margin 0 0 36px
		
	/*SECTION HOME CARE PASSO 3*/
	.step-3
		.form-button
			&.-next
				margin-top 31px
				
	/*SECTION HOME CARE PASSO 4*/
	.step-4
		input 
			background #f6f6f6
			border 0
			color $color-gray
			font-size 18px
			font-weight 400
			padding 15px 23px
			height 64px
			
		.form-submit
			padding 11px 37px


	/*SECTION HOME CARE PASSO 5*/
	.step-5
		padding-top 20px
		
		i 
			margin 0 0 31px
		
		h2
			font-size 18px
			font-weight 900
			color #454545
			margin 0 0 -14px

		p
			font-size 16px
			font-weight 300
			color #454545
			margin 0 0 46px

	/*SECTION SCHEDULE-OPERATION*/
	.bg-blue-shadow
		background url('../img/home/blue-shadow.png') no-repeat
		background-position top
		
	.schedule-operation
		text-align center
		padding-top 77px
		
		> hr
			width 107px
			display block
			margin 66px auto 0
			
			+breakpoint( tablet )
				margin-top 26px
		
		.steps
			position relative
			height 70px
			width 840px
			margin 0 auto 54px
			
			hr
				border-top 1px solid #e3e4e4
				margin auto
				position absolute
				top 0
				bottom 0
				width 100%
				height 1px
				z-index 5
				
		.circle-number
			width 70px
			height 70px
			background #fff
			color #a2ba54
			font-size 26px
			font-weight 900
			border-radius 50%
			padding 16px
			border 1px solid #e3e4e4
			position absolute
			margin auto
			z-index 10
			
			&.-one
				left -15px
				
			&.-two
				left 0
				right 0
				
			&.-three
				right -15px
		
		.icon
			position absolute
			top -43px
			left 0
			right 0
			margin auto

	.schedule-operation-title
		font-size 34px
		font-weight 300
		color #454545
		margin 0 0 65px
		
	.schedule-operation-step
		font-size 24px
		font-weight 700
		color #454545
		margin 0 0 36px
		
		+breakpoint( tablet )
			margin-bottom 15px
			font-size 21px
		

	.schedule-operation-text
		font-size 16px
		font-weight 400
		color $color-gray
		line-height 28px
		margin 0
		
		+breakpoint( tablet )
			margin-bottom 40px
		

	/*SECTION OUR-SPECIALITIES*/
	.our-specialties
		padding-top 66px
		padding-bottom 100px
		text-align center
		
		+breakpoint( tablet )
			padding-bottom 36px
		
		.form-button
			font-size 14px
			font-weight 400
			display inline-block
			width auto
			padding 7px 19px
			text-transform none
			
		.block-highlight
			padding 35px 18px 35px
			
			+breakpoint( tablet )
				margin-bottom 30px
			
		.icon
			height 70px
			margin-bottom 11px
		
	.our-specialties-title
		font-size 34px
		font-weight 300
		color #454545
		margin 0 0 19px
		
	.our-specialties-subtitle
		font-size 18px
		font-weight 400
		color $color-gray
		margin 0 0 67px
		
	.our-specialties-name
		font-size 24px
		font-weight 700
		color #454545
		margin 0 0 23px
		
	.our-specialties-text
		font-size 16px
		font-weight 400
		color $color-gray
		margin 0
		line-height 24px
		height 480px
		overflow hidden


	/*SECTION MORE-ADVANTAGES*/
	.more-advantages
		text-align center
		margin-left -15px
		margin-right -15px
		background url('../img/home/more-advantages-banner.png') no-repeat
		background-size cover
		background-position center
		z-index 20
		height 660px
		padding-top 106px
		position relative
		
		+breakpoint( tablet )
			background #257292
			height auto
			padding-top 60px
			padding-bottom 20px
		
		.icon
			height 70px
			margin-bottom 11px
		
	.more-advantages-title
		font-size 34px
		font-weight 900
		margin 0 0 37px
		
	.more-advantages-text
		font-size 18px
		font-weight 400
		margin 0 0 76px
		line-height 27px
		
	.more-advantages-description
		font-size 18.05px
		font-weight 900
		margin 0
		line-height 22px
		
		+breakpoint( tablet )
			margin-bottom 40px

	/*SECTION CENTRAL-QUALITY*/
	.central-quality
		text-align left
		margin-left -15px
		margin-right -15px
		background url('../img/home/central-quality-banner.jpg') no-repeat
		background-size cover
		background-position center
		z-index 10
		height 597px
		position relative
		margin-top -22px
		padding-top 121px
		
		+breakpoint( tablet )
			background #f2f8f8
			height auto
			padding 60px 0
			margin-top 0

	.central-quality-title
		font-size 34px
		font-weight 300
		color #454545
		margin 0 0 33px
		
	.central-quality-title-text		
		font-size 16px
		font-weight 400
		color $color-gray
		margin 0 0 27px
		line-height 28px
		
		+breakpoint( tablet )
			&:last-child
				margin-bottom 0
		
		span 
			color #454545
			
			
		a
			color #53bfeb
			font-weight 700
			text-decoration underline
			display inline-block
			

	/*SECTION SECURITY-TRUST-TITLE*/
	.security-trust
		padding-top 140px
		padding-bottom 111px
		
		+breakpoint( tablet )
			padding 60px 0
		
		.block-highlight
			padding 31px 15px 38px 38px
			
		.icon 
			display inline-block
			vertical-align top
			
			+breakpoint( tablet )
				display none
			
		.block-highlight-header
			width 335px
			display inline-block
			vertical-align top
			margin-bottom 23px
			margin-right 36px
			
			+breakpoint( tablet )
				width auto
				margin 0 0 30px

		.block-highlight-title
			font-size 34px
			font-weight 900
			color #454545
			display inline-block
			margin 0 0 16px
			
		.block-highlight-subtitle
			font-size 24px
			font-weight 900
			color #a2ba54
			display inline-block
			margin 0
			
			+breakpoint( tablet )
				font-size 20px
			
		.block-highlight-description
			font-size 16px
			font-weight 400
			color $color-gray
			margin 0 0 34px
			line-height 24px
			
		.form-button
			font-size 14px
			font-weight 400
			width auto
			margin 0
			text-transform none
			display inline-block
			padding 6px 21px
			
	.security-trust-title
		font-size 34px
		font-weight 300
		margin 17px 0 19px
		color #454545
		
	.security-trust-subtitle
		font-size 18px
		font-weight 400
		margin 0 0 28px
		color $color-gray
		
	.security-trust-number
		font-size 48px
		font-weight 400
		color #266988
		margin 0 0 2px
		
	.security-trust-number-title
		font-size 22px
		font-weight 400
		color #53bfeb
		margin 0 0 14px
		
	.security-trust-description
		font-size 16px
		font-weight 400
		color $color-gray
		margin 0
		line-height 24px
		
		+breakpoint( tablet )
			margin-bottom 30px

	/*SECTION DOUBTS*/
	.doubts		
		background url('../img/home/doubts-banner.png') no-repeat
		background-size cover
		background-position center
		height 793px
		margin-left -15px
		margin-right -15px
		position relative
		padding-top 82px
		
		+breakpoint( tablet )
			background #f5f5f5
			padding 60px 0
			height auto
		
		i
			font-size 39px
			color #266988
			float right
			margin-top -7px
			
			+breakpoint( tablet )
				position absolute
				right 15px 
				top 25px
		
	.doubts-title
		font-size 34px
		font-weight 300
		margin 0 0 58px
		color #454545
		
		+breakpoint( tablet )
			font-size 30px
		
		strong
			font-weight 900
			
	.block-drop
		background #fff
		border 1px solid #e5e5e5
		border-radius 5px
		padding 27px 32px
		margin-bottom 10px 
		cursor pointer
		position relative
		
		+breakpoint( tablet )
			padding 15px
			
		article
			display none
		
	.block-drop-title
		font-size 20px
		font-weight 700
		color #53bfeb
		margin 0
		display inline-block
		
		+breakpoint( tablet )
			font-size 18px
		
	.block-drop-highlight
		font-size 16px
		font-weight 700
		color $color-gray
		margin 25px 0 0
		display block 
		
	.block-drop-text
		font-size 16px
		font-weight 400
		color $color-gray
		margin 48px 0 0
		line-height 24px
		

	/*MODAL*/
	.specialties-modal
		width 51%
		margin-left -25.5%
		
		.specialtie
			float left
			
			i 
				vertical-align top
			
			h2 
				font-size 18px
				font-weight 300
				color #454545
				display inline-block
				padding 5px 0 0 19px
				margin 0
				
				span
					font-size 40px 
					font-weight 900
					color #f17862
					display block
				
		.close-reveal-modal
			background #266988
			padding 12px
			font-size 15px
			font-weight 400
			top 31px
			right 43px
			border-radius 5px
		
		.specialties-modal-header
			background #fff
			height 137px
			border-bottom 1px solid #e5e5e5
			border-top-right-radius 5px
			border-top-left-radius 5px
			padding 39px 36px
			
		.specialties-modal-body
			height 445px
			background #fff
			border-bottom-right-radius 5px
			border-bottom-left-radius 5px
			text-align center
			
			img
				margin 38px auto 17px
				
			h3
				color #f17862
				font-weight 900
				font-size 24px
				margin 0 0 42px
				
				
			p 
				color $color-gray
				font-size 16px
				font-weight 400
				margin 0
				line-height 24px
				

	&.home-flow		
		.home-care
			height auto
			
	.list-result
		list-style none
		background #fff
		padding 5px 0
		text-align left 
		position absolute
		top 90%
		width 100%
		z-index	100
		border 1px solid #ccc
		border-top 0
		border-bottom-left-radius 4px
		border-bottom-right-radius 4px
		display none
		overflow hidden
		overflow-y scroll
		+breakpoint( full-hd )
			max-height 340px
		+breakpoint( laptop )
			max-height 132px
		+breakpoint( tablet-desktop )
			max-height 132px
		+breakpoint( desktop )
			max-height 132px
		li 
			font-size 14px 
			color $color-gray
			margin 0
			padding 5px 20px
			cursor pointer
			z-index 9999
			
			&:hover
				background $color-gray
				color #f6f6f6
			a
				color $color-gray
	.steps-home
		display block
		position relative
		height 70px
		width 840px
		margin 0 auto 54px
		+breakpoint( mobile )
			display none
		+breakpoint( large-mobile )
			display none
		+breakpoint( tablet )
			display none
		hr
			border 0
			border-top 1px solid #e3e4e4
			margin auto
			position absolute
			top 0
			bottom 0
			width 100%
			height 1px
			z-index 5	
		.circle-number
			width 70px
			height 70px
			background #fff
			color #a2ba54
			font-size 26px
			font-weight 900
			border-radius 50%
			padding 16px
			border 1px solid #e3e4e4
			position absolute
			margin auto
			z-index 10		
		.circle-number.-one
			left -15px
		.circle-number.-two
			left 0
			right 0
		.circle-number.-three
			right -15px
			
	.input-search-attendance
		position relative
		
	.attendance-type
		margin-bottom 0
			
	.attendance-type:focus + .list-result
		border-color #66afe9
		outline 0
		box-shadow 0px 6px 10px -2px rgba(102,175,233,0.6)
			
.forgot-password-user
	text-align center
	margin-bottom 40px
	margin-top 10px
	a
		position relative
		color #428bca
		border-bottom 1px solid #428bca
		text-decoration none
		font-style italic	
		font-size 14px
		span
			position absolute
			width 100%
			bottom -2px
			height 1px
			background-color #a2d8ef	

		
			