@import 'nib'
@import '../../../assets/styl/utils.styl'

.notifications-component
	display none
	ul 
		list-style none 
		margin 0 
		padding 0
		
	li 
		background #f1f2f2
		padding 10px
		
		&:last-child
			.hr 
				display none
		
	.notifications-img
		margin-right 10px
		display inline-block
		width 50px 
		height 50px
		
	.notifications-text
		display inline-block
		width 65%
		vertical-align top
		
	.hr 
		background url( '../img/icons/hr.png' ) no-repeat
		width 80%
		height 1px
		display block 
		margin 20px auto 0
		
	.link-style-3
		display block
		text-decoration underline