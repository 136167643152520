@import 'nib'
@import 'utils.styl'

[data-page='home-profissional']

.home
	padding-bottom 0px		
	
	figcaption
	a
	h2
	h3
	h4
	label
	p
	input
	select
		font-family $font-family-roboto
		color #fff
		
	i
		display inline-block
		vertical-align middle
		
	input 
		font-size 18px
		font-weight 400
		font-style italic
		color $color-gray
	.block-bluelight
		border 1px solid $color-blue
		border-radius 5px
		padding 30px 48px 48px 48px
		margin-top 30px
		+breakpoint( mobile )
			padding 30px 5px
		div.icon
			text-align center
		h5
			color $color-gray
			font-size 30px
			text-align center
			font-family $font-family-titles
			margin-top 0
			text-transform uppercase

		.patients-attended
			color $color-blue
			font-size 36px
			font-family $font-family-base
			font-weight 700
			text-align center
			margin-bottom 0
			margin-top 15px
			line-height 1
		span
			font-size 16px
			color $color-gray
			font-family $font-family-base
			display block
			text-align center
		figure
			margin-top 53px
	.block-bluelight-little
		border 1px solid $color-blue
		border-radius 5px
		padding 30px 30px 40px 30px
		margin-top 30px
		div.icon
			text-align center
		h5
			color $color-gray
			font-size 30px
			text-align center
			font-family $font-family-titles
			margin-top 0
			text-transform uppercase
		.patients-attended
			color $color-blue
			font-size 36px
			font-family $font-family-base
			font-weight 700
			text-align center
			margin-bottom 0
			margin-top 15px
			line-height 1
			.text-low
				display inline-block
				font-size 24px
				color $color-blue
		span
			font-size 16px
			color $color-gray
			font-family $font-family-base
			display block
			text-align center		

			
